/*** IMPORTS FROM imports-loader ***/
var Blockly = require("../shim/blocks_compressed_vertical-blockly_compressed_vertical-messages");
var goog = require("../shim/blockly_compressed_vertical.goog");

// This file was automatically generated.  Do not modify.

'use strict';

goog.provide('Blockly.ScratchMsgs.allLocales');

goog.require('Blockly.ScratchMsgs');

Blockly.ScratchMsgs.locales["tr"] =
{
    "CONTROL_FOREVER": "sürekli tekrarla",
    "CONTROL_REPEAT": "%1 defa tekrarla",
    "CONTROL_IF": "eğer %1 ise",
    "CONTROL_ELSE": "değilse",
    "CONTROL_STOP": "durdur",
    "CONTROL_STOP_ALL": "tümü",
    "CONTROL_STOP_THIS": "bu komut dosyası",
    "CONTROL_STOP_OTHER": "sprite'daki diğer sprite dosyaları",
    "CONTROL_WAIT": "%1 saniye bekle",
    "CONTROL_WAITUNTIL": "%1 olana kadar bekle",
    "CONTROL_REPEATUNTIL": "%1' olana kadar tekrarla",
    "CONTROL_WHILE": "%1 olana kadar",
    "CONTROL_FOREACH": "%2 içindeki her %1 için",
    "CONTROL_STARTASCLONE": "ikiz olarak başladığımda",
    "CONTROL_CREATECLONEOF": "%1 in ikizini yarat",
    "CONTROL_CREATECLONEOF_MYSELF": "kendim",
    "CONTROL_DELETETHISCLONE": "bu ikizi sil",
    "CONTROL_COUNTER": "sayıcı",
    "CONTROL_INCRCOUNTER": "artış sayacı",
    "CONTROL_CLEARCOUNTER": "sayacı temizle",
    "CONTROL_ALLATONCE": "hepsi birden",
    "DATA_SETVARIABLETO": "%1 değişkenini %2 yap",
    "DATA_CHANGEVARIABLEBY": "%1 i %2 kadar değiştir",
    "DATA_SHOWVARIABLE": "%1 değişkenini göster",
    "DATA_HIDEVARIABLE": "%1 değişkenini gizle",
    "DATA_ADDTOLIST": "%1 i %2 ye ekle",
    "DATA_DELETEOFLIST": "%2 nin %1 ini sil",
    "DATA_DELETEALLOFLIST": "%1 in tümünü sil",
    "DATA_INSERTATLIST": "%3 ün %2 sine %1 ekle",
    "DATA_REPLACEITEMOFLIST": "%2 öğesinin %1 öğesini %3 ile değiştir",
    "DATA_ITEMOFLIST": "öğe %1 / %2",
    "DATA_ITEMNUMOFLIST": "%2 içindeki %1 öğesi #",
    "DATA_LENGTHOFLIST": "%1 uzunluğu",
    "DATA_LISTCONTAINSITEM": "%1 %2 içeriyor mu?",
    "DATA_SHOWLIST": "%1 listesini göster",
    "DATA_HIDELIST": "%1 listesini gizle",
    "DATA_INDEX_ALL": "tüm",
    "DATA_INDEX_LAST": "en son",
    "DATA_INDEX_RANDOM": "rastgele",
    "EVENT_WHENARDUINOBEGIN": "Pybot başladığında",
    "EVENT_WHENMICROBITBEGIN": "Mikrobit başladığında",
    "EVENT_WHENMICROBITBUTTONPRESSED": "%1 düğmesine basıldığında",
    "EVENT_WHENMICROBITPINBEINGTOUCHED": "%1 pimine dokunulduğunda",
    "EVENT_WHENMICROBITGESTURE": "%1 hareketi yapıldığında",
    "EVENT_WHENFLAGCLICKED": "%1 tıkladığında",
    "EVENT_WHENTHISSPRITECLICKED": "bu kuklaya tıklandığında",
    "EVENT_WHENSTAGECLICKED": "sahne tıklandığında",
    "EVENT_WHENTOUCHINGOBJECT": "bu hareketli grafik %1'e dokunduğunda",
    "EVENT_WHENBROADCASTRECEIVED": "%1 haberini aldığımda",
    "EVENT_WHENBACKDROPSWITCHESTO": "dekor %1 olduğunda",
    "EVENT_BACKDROP1": "dekor 1",
    "EVENT_WHENGREATERTHAN": "%1 > %2 olduğunda",
    "EVENT_WHENGREATERTHAN_TIMER": "zamanlayıcı",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ses yüksekliği",
    "EVENT_BROADCAST": "%1 haberini sal",
    "EVENT_BROADCASTANDWAIT": "%1 haberini sal ve bekle",
    "EVENT_WHENKEYPRESSED": "%1 tuşuna basıldığında",
    "EVENT_WHENMICROBITGESTURE_SHAKEN": "sarsılmış",
    "EVENT_WHENMICROBITGESTURE_TILTEDUPWARD": "yukarı doğru eğildi",
    "EVENT_WHENMICROBITGESTURE_TILTEDDOWNWARD": "aşağı doğru eğilmiş",
    "EVENT_WHENMICROBITGESTURE_TILTEDDLEFTWARD": "sola eğik",
    "EVENT_WHENMICROBITGESTURE_TILTEDDRIGHTWARD": "sağa eğik",
    "EVENT_WHENMICROBITGESTURE_FACEUP": "yüz yukarı",
    "EVENT_WHENMICROBITGESTURE_FACEDOWN": "yüzü aşağı dönük",
    "EVENT_WHENMICROBITGESTURE_FREEFALL": "serbest düşüş",
    "EVENT_WHENKEYPRESSED_SPACE": "boşluk",
    "EVENT_WHENKEYPRESSED_LEFT": "sol ok",
    "EVENT_WHENKEYPRESSED_RIGHT": "sağ ok",
    "EVENT_WHENKEYPRESSED_DOWN": "aşağı ok",
    "EVENT_WHENKEYPRESSED_UP": "yukarı ok",
    "EVENT_WHENKEYPRESSED_ANY": "herhangi",
    "LOOKS_SAYFORSECS": "%2 saniye boyunca %1 de",
    "LOOKS_SAY": "%1 de",
    "LOOKS_HELLO": "Merhaba!",
    "LOOKS_THINKFORSECS": "%2 saniye boyunca %1 diye düşün",
    "LOOKS_THINK": "%1 diye düşün",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "göster",
    "LOOKS_HIDE": "gizle",
    "LOOKS_HIDEALLSPRITES": "tüm spriteları gizle",
    "LOOKS_EFFECT_COLOR": "renk",
    "LOOKS_EFFECT_FISHEYE": "balık gözü",
    "LOOKS_EFFECT_WHIRL": "girdap",
    "LOOKS_EFFECT_PIXELATE": "pikselleştirmek",
    "LOOKS_EFFECT_MOSAIC": "mozaik",
    "LOOKS_EFFECT_BRIGHTNESS": "parlaklık",
    "LOOKS_EFFECT_GHOST": "hayalet",
    "LOOKS_CHANGEEFFECTBY": "%1 etkisini %2 değiştir",
    "LOOKS_SETEFFECTTO": "%1 etkisini %2 yap",
    "LOOKS_CLEARGRAPHICEFFECTS": " görsel etkileri temizle ",
    "LOOKS_CHANGESIZEBY": "boyutu %1 birim değiştir",
    "LOOKS_SETSIZETO": "boyutu % %1 yap",
    "LOOKS_SIZE": "büyüklük",
    "LOOKS_CHANGESTRETCHBY": "uzatmayı %1 ile değiştir",
    "LOOKS_SETSTRETCHTO": "uzatmayı %1 % olarak ayarla",
    "LOOKS_SWITCHCOSTUMETO": "%1 kılığına geç",
    "LOOKS_NEXTCOSTUME": "sonraki kostüm",
    "LOOKS_SWITCHBACKDROPTO": "%1 dekoruna geç",
    "LOOKS_GOTOFRONTBACK": "%1 katmanına git",
    "LOOKS_GOTOFRONTBACK_FRONT": "ön",
    "LOOKS_GOTOFRONTBACK_BACK": "arka",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%2 katman %1 git",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ileri",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "geri",
    "LOOKS_BACKDROPNUMBERNAME": "dekor %1",
    "LOOKS_COSTUMENUMBERNAME": "kostüm %1",
    "LOOKS_NUMBERNAME_NUMBER": "sayı",
    "LOOKS_NUMBERNAME_NAME": "isim",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "arka planı %1 olarak değiştir ve bekle",
    "LOOKS_NEXTBACKDROP_BLOCK": "sonraki dekor",
    "LOOKS_NEXTBACKDROP": "sonraki dekor",
    "LOOKS_PREVIOUSBACKDROP": "önceki dekor",
    "LOOKS_RANDOMBACKDROP": "rastgele dekor",
    "MOTION_MOVESTEPS": "%1 adım git",
    "MOTION_TURNLEFT": "%1 %2 derece dön",
    "MOTION_TURNRIGHT": "%1 %2 derece dön",
    "MOTION_POINTINDIRECTION": "%1 yönüne dön",
    "MOTION_POINTTOWARDS": "%1 e doğru yönel",
    "MOTION_POINTTOWARDS_POINTER": "fare imleci",
    "MOTION_POINTTOWARDS_RANDOM": "rastgele yön",
    "MOTION_GOTO": "%1 e git",
    "MOTION_GOTO_POINTER": "fare imleci",
    "MOTION_GOTO_RANDOM": "rastgele konum",
    "MOTION_GOTOXY": "x: %1 y: %2 konumuna git",
    "MOTION_GLIDESECSTOXY": "%1 saniyede x: %2 y: %3 konumuna git",
    "MOTION_GLIDETO": "%1 saniyede %2 git",
    "MOTION_GLIDETO_POINTER": "fare imleci",
    "MOTION_GLIDETO_RANDOM": "rastgele konum",
    "MOTION_CHANGEXBY": "x konumunu %1 değiştir",
    "MOTION_SETX": "x konumunu %1 yap",
    "MOTION_CHANGEYBY": "y konumunu %1 değiştir",
    "MOTION_SETY": "y konumunu %1 yap",
    "MOTION_IFONEDGEBOUNCE": "kenara geldiyse sek",
    "MOTION_SETROTATIONSTYLE": "dönüş stilini %1 yap",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "sol-sağ",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "döndürme",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "her yerde",
    "MOTION_XPOSITION": "x konumu",
    "MOTION_YPOSITION": "y konumu",
    "MOTION_DIRECTION": "yön",
    "MOTION_SCROLLRIGHT": "sağa kaydır %1",
    "MOTION_SCROLLUP": "%1 yukarı kaydır",
    "MOTION_ALIGNSCENE": "sahneyi hizala %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "sol alt",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "sağ alt",
    "MOTION_ALIGNSCENE_MIDDLE": "orta",
    "MOTION_ALIGNSCENE_TOPLEFT": "Sol üst",
    "MOTION_ALIGNSCENE_TOPRIGHT": "sağ üst",
    "MOTION_XSCROLL": "x kaydır",
    "MOTION_YSCROLL": "y kaydır",
    "MOTION_STAGE_SELECTED": "Aşama seçildi: hareket bloğu yok",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "%1 ile %2  arasında rastgele bir sayı seç",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 ve %2",
    "OPERATORS_OR": "%1 veya %2",
    "OPERATORS_NOT": "%1 değil",
    "OPERATORS_JOIN": "%1 i ve %2 ile birleştir",
    "OPERATORS_JOIN_APPLE": "elma",
    "OPERATORS_JOIN_BANANA": "muz",
    "OPERATORS_LETTEROF": " %2 in %1 .harfi",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "%1 in uzunluğu",
    "OPERATORS_CONTAINS": "%1 %2 içeriyor mu?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "%1 i yuvarla",
    "OPERATORS_MATHOP": "%2 in %1 i",
    "OPERATORS_MATHOP_ABS": "mutlak değer",
    "OPERATORS_MATHOP_FLOOR": "bir alt sayıya yuvarla",
    "OPERATORS_MATHOP_CEILING": "bir üst sayıya yuvarla",
    "OPERATORS_MATHOP_SQRT": "karekök al",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "tanımla %1",
    "SENSING_TOUCHINGOBJECT": "%1 e değiyor mu?",
    "SENSING_TOUCHINGOBJECT_POINTER": "fare imleci",
    "SENSING_TOUCHINGOBJECT_EDGE": "kenar",
    "SENSING_TOUCHINGCOLOR": "%1 rengine değiyor mu?",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 rengi %2 rengine değiyor mu?",
    "SENSING_DISTANCETO": "%1 e mesafe",
    "SENSING_DISTANCETO_POINTER": "fare imleci",
    "SENSING_ASKANDWAIT": "%1  diye sor ve bekle",
    "SENSING_ASK_TEXT": "Adın ne?",
    "SENSING_ANSWER": "Yanıt",
    "SENSING_KEYPRESSED": "%1  tuşuna basılı mı?",
    "SENSING_MOUSEDOWN": "fareye basıldı mı?",
    "SENSING_MOUSEX": "farenin x i",
    "SENSING_MOUSEY": "farenin y si",
    "SENSING_SETDRAGMODE": "sürükleme modunu %1 yap",
    "SENSING_SETDRAGMODE_DRAGGABLE": "sürüklenebilir",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "sürüklenemez",
    "SENSING_LOUDNESS": "ses yüksekliği",
    "SENSING_LOUD": "yüksek sesle?",
    "SENSING_TIMER": "zamanlayıcı",
    "SENSING_RESETTIMER": "zamanlayıcıyı sıfırla",
    "SENSING_OF": "%1 of %2",
    "SENSING_OF_XPOSITION": "x position",
    "SENSING_OF_YPOSITION": "y position",
    "SENSING_OF_DIRECTION": "direction",
    "SENSING_OF_COSTUMENUMBER": "costume #",
    "SENSING_OF_COSTUMENAME": "costume name",
    "SENSING_OF_SIZE": "size",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "backdrop #",
    "SENSING_OF_BACKDROPNAME": "backdrop name",
    "SENSING_OF_STAGE": "Stage",
    "SENSING_CURRENT": "şuanki %1",
    "SENSING_CURRENT_YEAR": "yıl",
    "SENSING_CURRENT_MONTH": "ay",
    "SENSING_CURRENT_DATE": "tarih",
    "SENSING_CURRENT_DAYOFWEEK": "haftanın günü",
    "SENSING_CURRENT_HOUR": "saat",
    "SENSING_CURRENT_MINUTE": "dakika",
    "SENSING_CURRENT_SECOND": "Saniye",
    "SENSING_DAYSSINCE2000": "2000 den beri geçen gün",
    "SENSING_USERNAME": "Kullanıcı adı",
    "SENSING_USERID": "kullanıcı id",
    "SOUND_PLAY": "%1 sesini başlat",
    "SOUND_PLAYUNTILDONE": `%1 sesi bitene kadar çal`,
    "SOUND_STOPALLSOUNDS": "tüm sesleri durdur",
    "SOUND_SETEFFECTO": "%1 etkisini %2 yap",
    "SOUND_CHANGEEFFECTBY": "%1 etkisini %2 değiştir",
    "SOUND_CLEAREFFECTS": " ses etkilerini temizle ",
    "SOUND_EFFECTS_PITCH": "ses perdesi",
    "SOUND_EFFECTS_PAN": "sola/sağa kaydır",
    "SOUND_CHANGEVOLUMEBY": "ses seviyesini %1 değiştir",
    "SOUND_SETVOLUMETO": "ses seviyesini %1% yap",
    "SOUND_VOLUME": "Ses düzeyi",
    "SOUND_RECORD": "kayıt...",
    "CATEGORY_MOTION": "Hareket",
    "CATEGORY_LOOKS": "Görünüm",
    "CATEGORY_SOUND": "Ses",
    "CATEGORY_EVENTS": "Olaylar",
    "CATEGORY_ENTERTAINMENT": "Eğlence",
    "CATEGORY_CONTROL": "Kontrol",
    "CATEGORY_SENSING": "Algılama",
    "CATEGORY_LIGHTING": "Aydınlatma",
    "CATEGORY_SPEAKER": "Hoparlör",
    "CATEGORY_NETWORK": "Ağ",
    "CATEGORY_OPERATORS": "Operatörler",
    "CATEGORY_VARIABLES": "Değişkenler",
    "CATEGORY_MYBLOCKS": "Bloklarım",
    "CATEGORY_LIGHTS": "Lights",

    "DUPLICATE": "Kopyalamak",
    "DELETE": "Silmek",
    "ADD_COMMENT": "Yorum ekle",
    "REMOVE_COMMENT": "Yorumu Kaldır",
    "DELETE_BLOCK": "Blok Sil",
    "DELETE_X_BLOCKS": "%1 Blok Sil",
    "DELETE_ALL_BLOCKS": "Tüm %1 blok silinsin mi?",
    "CLEAN_UP": "Blokları Temizle",
    "HELP": "Yardım",
    "UNDO": "Geri alma",
    "REDO": "Yeniden yap",
    "EDIT_PROCEDURE": "Düzenlemek",
    "SHOW_PROCEDURE_DEFINITION": "Tanıma git",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Bir şey söyle...",
    "COLOUR_HUE_LABEL": "Renk",
    "COLOUR_SATURATION_LABEL": "Doyma",
    "COLOUR_BRIGHTNESS_LABEL": "Parlaklık",
    "CHANGE_VALUE_TITLE": "Değeri değiştir:",
    "RENAME_VARIABLE": "Değişkeni yeniden adlandır",
    "RENAME_VARIABLE_TITLE": "Tüm \"%1\" değişkenlerini şu şekilde yeniden adlandırın::",
    "RENAME_VARIABLE_MODAL_TITLE": "Değişkeni Yeniden Adlandır",
    "NEW_VARIABLE": "Bir Değişken Oluştur",
    "NEW_VARIABLE_TITLE": "Yeni değişken adı:",
    "VARIABLE_MODAL_TITLE": "Yeni Değişken",
    "VARIABLE_ALREADY_EXISTS": "\"%1\" adlı bir değişken zaten var.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "\"%2\" türünde başka bir değişken için \"%1\" adlı bir değişken zaten var.",
    "DELETE_VARIABLE_CONFIRMATION": "\"%2\" değişkeninin %1 kullanımı silinsin mi?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "\"%1\" değişkeni, \"%2\ işlevinin tanımının bir parçası olduğu için silinemiyor",
    "DELETE_VARIABLE": "\"%1\" değişkenini silin",
    "NEW_PROCEDURE": "Bir Blok Oluştur",
    "PROCEDURE_ALREADY_EXISTS": "\"%1\" adlı bir prosedür zaten var.",
    "PROCEDURE_DEFAULT_NAME": "blok adı",
    "PROCEDURE_USED": "Bir blok tanımını silmek için önce bloğun tüm kullanımlarını kaldırın",
    "NEW_LIST": "Bir Liste Oluştur",
    "NEW_LIST_TITLE": "Yeni liste adı:",
    "LIST_MODAL_TITLE": "Yeni liste",
    "LIST_ALREADY_EXISTS": "\"%1\" adlı bir liste zaten var.",
    "RENAME_LIST_TITLE": "Tüm \"%1\" listelerini şu şekilde yeniden adlandırın::",
    "RENAME_LIST_MODAL_TITLE": "Listeyi Yeniden Adlandır",
    "DEFAULT_LIST_ITEM": "şey",
    "DELETE_LIST": "\"%1\" listesini silin",
    "RENAME_LIST": "Listeyi yeniden adlandır",
    "NEW_BROADCAST_MESSAGE": "Yeni Mesaj",
    "NEW_BROADCAST_MESSAGE_TITLE": "Yeni mesaj adı:",
    "BROADCAST_MODAL_TITLE": "Yeni Mesaj",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "haber1",
    "OLED_CATEGORY":"OLED",
    "OLED_INIT":"\%1\ \%2\ oled başlat",
    "OLED_DRAWLINE":"\%1\ \%2\ çizgi çiz x0: \%3\ y0: \%4\, x1: \%5\ y1：\%6\ renk \%7\ ",
    "OLED_COLOR_WHITE":"beyaz",
    "OLED_COLOR_BLACK":"siyah",
    "OLED_COLOR_INVERSE":"ters renkler",
    "OLED_DRAWRECT":"\%1\ \%2\ doğru çiz x: \%3\ y: \%4\ genişlik \%5\ yükseklik \%6\ renk \%7\ ",
    "OLED_FILLRECT":"\%1\ \%2\ doğruyu doldur x: \%3\ y: \%4\ genişlik \%5\ yükseklik \%6\ renk \%7\ ",
    "OLED_DRAWCIRCLE":"\%1\ \%2\ daire çiz x: \%3\ y: \%4\ yarıçap \%5\ renk \%6\ ",
    "OLED_FILLCIRCLE":"\%1\ \%2\ daire doldur x: \%3\ y: \%4\ yarıçap \%5\ renk \%6\ ",
    "OLED_DRAWROUNDRECT":"\%1\ \%2\ yuvarlak doğru çiz x: \%3\ y: \%4\ genişlik \%5\ yükseklik \%6\ yarıçap \%7\ renk \%8\ ",
    "OLED_FILLROUNDRECT":"\%1\ \%2\ yuvarlak dikdörtgeni doldur x: \%3\ y: \%4\ genişlik \%5\ yükseklik \%6\ yarıçap \%7\ renk \%8\ ",
    "OLED_DRAWTRIANGLE":"\%1\ \%2\ üçgen çiz x0: \%3\ y0: \%4\, x1: \%5\ y1: \%6\, x2: \%7\ y2: \%8\ renk \%9\ ",
    "OLED_FILLTRIANGLE":"\%1\ \%2\ içi dolu üçgen çiz x0: \%3\ y0: \%4\, x1: \%5\ y1: \%6\, x2: \%7\ y2: \%8\ renk \%9\ ",
    "OLED_SETTEXT":"\%1\ \%2\ metin boyutunu ayarla \%3\ renk \%4\ arka plan rengi \%5\ ",
    "OLED_SETCURSOR":"\%1\ \%2\ imleci ayarla x: \%3\ y: \%4\ ",
    "OLED_PRINT":"\%1\ \%2\ yazdır \%3\ \%4\ ",
    "OLED_EOL_WARP":"yeni satır",
    "OLED_EOL_NOWARP":"aynı satır",
    "OLED_CLEAR":"\%1\ \%2\ oled temizle",
    "OLED_REFRESH":"\%1\ \%2\ oled yenile",
    "OLED_STARTSCROLL":"\%1\ \%2\ kaydırmayı başlat \%3\ y0: \%4\ y1: \%5\ ",
    "OLED_SCROLL_RIGHT":"sağ",
    "OLED_SCROLL_LEFT":"sol",
    "OLED_SCROLL_DIAGRIGHT":"sol diag",
    "OLED_SCROLL_DIAGLEFT":"sol diag",
    "OLED_STOPSCROLL":"\%1\ \%2\ kaydırmayı durdur",
    "RGBLEDSTRIP_INIT":"RGB LED strip başlat uzunluk \%1\ pin \%2\ ",
    "RGBLEDSTRIP_SETPIXELCOLOR":"\%1\ \%2\ pikselleri ayarla \%3\ renk \%4\ ",
    "RGBLED_LIGHTSUP":"\%1\ \%2\ RGB LED ışıkları aç \%3\ ",
    "RGBLEDSTRIP_FILL":"\%1\ \%2\ pikselden doldur \%3\ adet \%4\ renginde \%5\ ",
    "RGBLEDSTRIP_COLOR":"\%1\ \%2\ RGB LED strip renk R \%3\ G \%4\ B \%5\ ",
    "RGBLEDSTRIP_SETBRIGHTNESS":"\%1\ \%2\ parlaklık ayarla \%3\ ",
    "RGBLEDSTRIP_CLEAR":"\%1\ \%2\ hepsini temizle",
    "RGBLEDSTRIP_SHOW":"yenile",
    "RGBLED_YAK" : '\%1\ \%2\ RGB Led Yak \%3\ \%4\ \%5\ ',
    "RGBLED_SONDUR": '\%1\ \%2\ Tüm ledleri kapat',
    "RGB_LED_YAK_SURELI_PYBOARD " : '\%1\ \%2\ Led \%3\ renk \%4\ saniye \%5\ boyunca yak soket \%6\ ',
    "RGB_LED_YAK_SURESIZ_PYBOARD" : '\%1\ \%2\ Led \%3\ renk \%4\  yak soket \%5\ ',
    "RGB_LED_KAPAT_PYBOARD" : '\%1\ \%2\ Led \%3\  kapat soket \%4\ ',
    "RGBLED_YAK_PYBOARD" : '\%1\ \%2\ RGB Led Light \%3\  \%4\  \%5\ soket \%6\ ',
    "RGBLED_SONDUR_PYBOARD" : '\%1\ \%2\ Turn off all leds soket \%3\ ',

    "SES_CAL":"\%1\ \%2\ ses çal \%3\ ",
    "SES_CAL_BITENE_KADAR":"\%1\ \%2\ sel çal \%3\ bitene kadar",
    "TUM_SESLERI_DURDUR":"\%1\ \%2\ tüm sesleri durdur",
    "NOTA_CAL":"\%1\ \%2\ nota \%3\ çal \%4\ vuruş",
    "SUS_VURUS":"\%1\ \%2\ sus \%3\ vuruş",
    "FREKANSDA_CAL":"\%1\ \%2\ \%3\ Hz frekansında ses çal \%4\ saniye",
    "SES_SEVIYESINI_DEGİSTİR":"\%1\ \%2\ ses seviyesini değiştir \%3\ ",
    "SES_SEVIYESI_YUZDE":"\%1\ \%2\ ses seviyesi \%3\ %",
    "SES_SEVIYESI":"\%1\ \%2\ ses seviyesi",

    "ILERI_GIT":"\%1\ \%2\ ileri git \%3\ % güçte \%4\ saniye",
    "GERI_GIT":"geri git \%1\ % güçte \%2\ saniye",
    "SOLA_GIT":"\%1\ \%2\ sola dön \%3\ % güçte \%4\ saniye",
    "SAGA_GIT":"\%1\ \%2\ sağa dön \%3\ % güçte \%4\ saniye",
    "GERIYE_GIT":"\%1\ \%2\ geriye git \%3\ % güçte \%4\ saniye ",
    "SOLA_DON_DERECE_BITIMI":"\%1\ \%2\ sola dön \%3\ derece bitene kadar",
    "SAGA_DON_DERECE_BITIMI":"\%1\ \%2\ saga dön \%3\ derece bitene kadar",
    "SEC_GIT":"\%1\ \%2\ \%3\ \%4\ % güçte",
    "SEC_GIT_RPM":"\%1\ \%2\ \%3\ \%4\ % rpm",
    "SOLA_SAGA_DERECELI_GIT_RPM":"\%1\ \%2\ sol tekerlik \%3\ % rpm sağ tekerlik \%4\ % rpm",
    "SOLA_SAGA_DERECELI_GIT_POWER":"\%1\ \%2\ sol tekerlik \%3\ % güçte sağ tekerlik \%4\ % güçte",
    "HAREKETI_DURDUR":"\%1\ \%2\ hareketi durdur \%3\ ",
    "RESET_ENCODER_MOTOR":"\%1\ \%2\ \%3\ encoder motoru sıfırla",
    "MOTOR_MOVES_CM":"\%1\ \%2\ motorlar \%3\ yönünde \%4\ cm kadar git",
    "MOTOR_ROTATES_AT_TIME":"\%1\ \%2\ encoder motor \%3\ gücünde \%4\ \%5\ \%6\ saniye git",
    "MOTOR_ACIYA_GORE_DON":"\%1\ \%2\ encoder motor \%3\  \%4\ derece dön",
    "MOTOR_ROTATED_ANGLE":"\%1\ \%2\ encoder motor \%3\ \%4\ açıyla git",
    "MOTOR_ACI":"\%1\ \%2\ encoder motor \%3\  \%4\ saniye",
    "RPM_SANIYE_YON":"\%1\ \%2\ encoder motor \%3\ yönünde \%4\ rpm \%5\ saniye git",
    "GUC_SANIYE_YON":"\%1\ \%2\ encoder motor \%3\ yönünde \%4\ güc \%5\ saniye git",
    "MOTOR_ROTATES_AT":"\%1\ \%2\ encoder motor \%3\ dön \%4\ \%5\ ",
    "TEMPATURE_DHT": '\%1\ \%2\ Sıcaklık ve Bağıl nem sensörünü başlat block \%3\ ',
    "TEMPATURE_READ_DHT": '\%1\ \%2\ Sıcaklık oku block \%3\ ',
    "DUGME_BASILDIMI":"\%1\ \%2\ düğmeye basıldı mı?",
    "CIHAZ_BAGLANDIMI":"\%1\ \%2\ cihaz bağlandı mı?",
    "POTANSIYOMETRE_DEGERI":"\%1\ \%2\ dişli potansiyometre değeri",
    
    "CIHAZ_EGIKMI":"\%1\ \%2\ cihaz \%3\ eğik mi?",
    "CIHAZ_KONUMU":"\%1\ \%2\ cihaz \%3\ konumlandırılması",
    "X_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ x etrafında dönüş açısı",
    "Y_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ y etrafında dönüş açısı",
    "Z_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ z etrafında dönüş açısı",
    "SIFIRLA_DONDURME_ACISI":"\%1\ \%2\ sıfırla \%3\ döndürme açısı",
    "ZAMANLAYICI":"\%1\ \%2\ zamanlayıcı",
    "ZAMANLAYICI_SIFIRLA":"\%1\ \%2\ zamanlayıcıyı sıfırla",
    "ONUNDEKI_ENGELLER":"önündeki engeller",
    "RENK_DEGER_TESPITI":"\%1\ \%2\  \%3\ renk değeri tespit edildi",
    "RENK_ALGILAYICI_ORTAM_ISIK_YOGUNLUGU":"\%1\ \%2\ renk algılayıcı ortam ışık yoğunluğu",
    "RENK_ALGILAYICI_YANSIYAN_ISIK_YOGUNLUGU":"\%1\ \%2\ renk algılayıcı yansıyan ışık yoğunluğu",
    "RENK_ALGILAYICI_YANSIYAN_KIZILOTESI_ISIK_YOGUNLUGU":"\%1\ \%2\ renk algılayıcı yansıyan kızılötesi ışık yoğunluğu",
    "RENK_ALGILAYICI_GRI_OLCEK":"\%1\ \%2\ renk algılayıcı gri-ölçek değeri",
    "WIFI_CONNECT_SSID":"\%1\ \%2\ Wİ-Fİ ye bağlan \%3\ şifre \%4\ ",
    "WIFI_BAGLANDIMI":"\%1\ \%2\ Wİ-Fİ bağlımı?",
    "CONNECTED_WIFI_AGAIN":"\%1\ \%2\ Wİ-Fİ ye tekrar bağlan",
    "DISCONNECTED_WIFI_AGAIN":"\%1\ \%2\ Wİ-Fİ bağlantısını kes",
    "BUGUNUN_TARIHINI_GETIR":"\%1\ \%2\ \%3\ zaman dilimindeki tarihi getir \%4\ ",
    "RGB_LED_YAK_SURELI": "\%1\ \%2\ Led  \%3\ renk  \%4\ saniye  \%5\ boyunca yak",
    "RGB_LED_YAK_SURESIZ":"\%1\ \%2\ Led  \%3\ renk  \%4\ yak",
    "RGB_LED_KAPAT" : "\%1\ \%2\ Led  \%3\ kapat",
    "RGB_LED_BASLAT": "\%1\ \%2\ Led başlat",
    "MESAFE_VE_ISIK": "\%1\ \%2\ Mesafe ve ışık siddeti başlat",
    "PROMIXITY_UZAKLIK_SENSORU":"\%1\ \%2\ uzaklık değeri",
    "DISLI_POTANSIYOMETRI_DEGERI": "\%1\ \%2\ dişli potansiyometre değeri \%3\ ",
    "YESIL_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ yeşil ışık şiddeti",
    "MAVI_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ mavi ışık şiddeti",
    "KIRMIZI_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ kırmızı ışık şiddeti",
    "START_MOTION_SENSOR": "\%1\ \%2\ Hareket sensörünü başlat",
    "START_LINE_FOLLOWER": "\%1\ \%2\ Çizgi izleme sensörünü başlat",
    "START_LINE_FONKS": "\%1\ \%2\ Çizgi İzleme fonksiyonlarını oku \%3\ ",
    "READ_LINE_NEW": "\%1\ \%2\ çizgi izle \%3\ \%4\ ",
    "EGIM_ACISI_DURUMLU": "\%1\ \%2\  \%3\  eğim açısı",
    "X_Y_Z_ACISI_DURUMLU": "\%1\ \%2\  \%3\ eğim açısı",
    "X_Y_Z_HIZ_DURUMLU": "\%1\ \%2\  \%3\ hızı m/s^2",
    "BUZER_AC": "\%1\ \%2\ Buzzer Aç",
    "BUZER_KAPAT": "\%1\ \%2\ Buzzer Kapat",
    "FREKANSDA_SES_CAL":"\%1\ \%2\  \%3\ Hz frekansında ses çal \%4\ vuruş",
    "ILERI_GIT_TEXT":"ileri git",
    "GERI_GIT_TEXT":"geri git",
    "SOLA_GIT_TEXT":"sola dön",
    "SAGA_GIT_TEXT":"sağa dön",
    "LEFT_WHELL":"sol tekerlik EM(1)",
    "RIGHT_WHELL":"sağ tekerlik EM(2)",
    "ALL_WHELL":"hepsi",
    "RPM_TEXT":"dönme hızı(RPM)",
    "POWER_TEXT":"güç(%)",
    "SAG_TEXT":"sağ",
    "SOL_TEXT":"sol",
    "HEPSI_TEXT":"hepsi",
    "KIRMIZI":"kırmızı",
    "CAMGOBEGI":"camgöbeği",
    "MAVI":"mavi",
    "YESIL":"yeşil",
    "SARI":"sarı",
    "BEYAZ":"beyaz",
    "SIYAH":"siyah",
    "GALIBARDA":"galibarda",
    "ONE_EGIK":"öne eğik",
    "GERIYE_EGIK":"geriye doğru eğik",
    "SOLA_EGIK":"sola eğik",
    "SAGA_EGIK":"sağa eğik",
    "YUZU_YUKARI_DONUK":"yüzü yukarı dönük",
    "YUZU_ASAGI_DONUK":"yüzü aşağı dönük",
    "ILERI_EGIK_TEXT":"ileri eğik",
    "GERI_EGIK_TEXT":"geri eğik",
    "SOLA_EGIK_TEXT":"sola eğik",
    "SAGA_EGIK_TEXT":"sağa eğik",
    "SENSOR1":"sensör-1",
    "SENSOR2":"sensör-2",
    "SENSOR3":"sensör-3",
    "SENSOR4":"sensör-4",
    "SENSOR5":"sensör-5",
    "ORTAM_ISIK_SIDDETI_PYBOARD":"\%1\ \%2\ ortam ışık şiddeti soket \%3\ ",
    "YESIL_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ yeşil ışık şiddeti soket \%3\ ",
    "MAVI_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ mavi ışık şiddeti soket \%3\ ",
    "KIRMIZI_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ kırmızı ışık şiddeti soket \%3\ ",

    "ULTRASONIKINITPYBOARD" : "\%1\ \%2\ ultrasonik sensör başlat \%3\ ",
    "DISTANCEVALUEULTRASONIK" : "\%1\ \%2\ ultrasonik sensör ölçülen mesafe",
    "GETTIMEPYBOARD" : "\%1\ \%2\ \%3\ zaman dilimindeki tarihi getir  \%4\ ",

    "TEMPATURE_READ_PYBOT" : "\%1\ \%2\ sıcaklık oku \%3\ ",
    "RELATIVE_HUMIDITY_READ_PYBOT" : "\%1\ \%2\ bağıl nem oku \%3\ ",
    "WATERPROOF_TEMPATURE_INIT_PYBOARD" : "\%1\ \%2\ Su geçirmez sıcaklık sensörünü başlat  \%3\ ",
    "WATERPROOF_TEMPATURE_READ_PYBOARD" : "\%1\ \%2\ Su geçirmez sensörden sıcaklık oku \%3\ ",

    "SOUNDINTENSITY" : "\%1\ \%2\ ses şiddetini ölç \%3\ ",

    "BKY_CATEGORY_LIGHTS" : "Işık",
    "CLOCKWISE": " saat yönününde",
    "COUNTERCLOCKWISE": "saat yönünün tersinde",
   




    //FEELİNG

    "FEELING_BILGIN": 'Bilgin',
    "FEELING_HAVALI": 'Havalı',
    "FEELING_MERAKLI": 'Meraklı',
    "FEELING_MUTLU": 'Mutlu',
    "FEELING_SEVGI": 'Sevgi',
    "FEELING_UYKULU": 'Uykulu',
    "FEELING_KIZGIN":'Kızgın',
    "FEELING_KORKU": 'Korku',
    "FEELING_NESELİ": 'Neşeli',
    "FEELING_SASKIN": 'Şaşkın',
    "FEELING_UTANGAC": 'Utangaç',
    "FEELING_UZGUN": 'Üzgün',


    //DANCE

    "DANCE_TANGO": 'Tango',
    "DANCE_SAMBA": 'Samba',
    "DANCE_HIPHOP": 'Hip hop',
    "DANCE_SLOW": 'Slow',


    //MUSIC
    "HIPHOP": 'hiphop',
    "AT": 'at',
    "AYAKSESI": 'ayak sesi',
    "BOZUKPARA": 'bozuk para',
    "HAV": 'hav',
    "OYUNCAK": 'oyuncak',
    "PUAN": 'puan',
    "MIYAV": 'miyav',
    "SIREN": 'siren',


    //DIRECTION

    "ILERI": 'ileri',
    "GERI": 'geri',
    "SAG": 'sağ',
    "SOL": 'sol',
    "Z": 'z',
    "HEPSI":"hepsi",

    //TILTED

    "LEFTTITLTED": 'sola yatık',
    "RIGHTTITLTED": 'sağa yatık',
    "FRONTTITLTED": 'öne yatık',
    "BACKTITLTED": 'arkaya yatık',
    "STRAIGHTTITLTED":"düz",


    "CLOCKWISE": 'saat yönünü',
    "COUNTERCLOCKWISE": 'saat yönünün tersi',


    "TEMPATURE": 'sıcaklık',
    "HUMIDITY": 'nem',
    "SOILHUMIDITY": 'toprak nemi',
    "SES": 'ses',
    "ISIK": 'ışık',
    "SALLANMA": 'sallanma',
    "ENGELVAR": 'var',
    "ENGELYOK": 'yok',


     //color

     "KIRMIZI": 'kırmızı',
     "YESIL": 'yeşil',
     "MAVI": 'mavi',
     "PEMBE": 'pembe',
     "CAMGOBEGI": 'camgöbeği',
     "SARI": 'sarı',
     "BEYAZ": 'beyaz',
     "TURKUAZ": "turkuaz",
     "SIYAH": "siyah",
     "MOR": "mor",





   //LIGHT SHOW

   "GOKKUSAGI": 'Gökkuşağı',
   "KUZEYISIKLARI": 'Kuzey Işıkları',
   "KUYRUKLUYILDIZ": 'Kuyruklu Yıldız',
   "METEORYAGMURU": 'Meteor Yağmuru',
   "POLISARABASI": 'Polis Arabası',
   "TRAFIKLAMBASI": 'Trafik Lambası',
   "SAHNEISIKLARI": 'Sahne Işıkları',
   "KAMPATESI": 'Kamp Ateşi',


    //ZBOT_SOUND

    "ZBOT_SES_CAL": "\%1\ sesini çal",
    "ZBOT_SES_CAL_BITENE_KADAR":  "\%1\ sesi bitene kadar çal",
    "ZBOT_SES_SES_SEVIYESI":  "ses seviyesini \%1\ % yap",
    "ZBOT_SES_SEVIYESI": "ses seviyesi",
    "ZBOT_SES_SEVIYESINI_DEGISTIR": "ses seviyesini \%1\ değiştir",
    "ZBOT_NOTA_CAL": "\%1\ \%2\ notasını çal",
    "ZBOT_TUM_SESLERI_DURDUR": "sesleri durdur",
    "MY_PLAY_DRUM_FOR_BEATS": "\%1\ davulunu \%2\ vuruş çal",
    "MY_PLAY_MIDI_FOR_BEATS": "\%1\ vuruş durakla",
    "MY_REST_FOR_BEATS": "\%1\ notasını \%2\ vuruşunda çal",
    "MY_SET_INSTRUMENT": "Enstrümanı \%1\ yap",
    "MY_SET_TEMPO": "tempoyu \%1\ yap",
    "MY_CHANGE_TEMPO": "tempoyu \%1\ değiştir",
    "MY_GET_TEMPO": "tempo",
   

    //ZBOT_SENSING

    "ZBOT_DUGME_BASILDIMI": "\%1\ tuşuna basıldı?",
    "CIHAZ_SALLANIYORMU":"zBot sallandı?",
    "ALGILANAN_RENK":"\%1\ renk algılandı?",
    "ZBOT_CIZGI_ALGILAMA_RENK": "çizgi algılayıcı \%1\ taraf \%2\ algıladı ise başla",
    "ZBOT_ONUNDEKI_ENGELLER": "engel algılandı?",
    "SES_SIDDETI": "ses şiddeti",
    "SALLANMA_SIDDETI":"sallanma şiddeti",
    "PIL_SEVIYESI":"pil seviyesi",
    "EGIM_ACISI":"eğim açısı",
    "ORTAM_ISIK_SIDDETI":"ışık şiddeti",
    "YATIS_ACISI":"yatış açısı",


    //ZBOT_LEDMATRIX

    "ZBOT_ZZ_LED_MATRIX": "\%1\ ekrana çiz",
    "ZBOT_ZZ_LED_MATRIX_SANIYE": "\%1\ ekrana çiz \%2\ saniye",
    "ZBOT_ZZ_LED_MATRIX_EKRANA_CIZ": "\%1\ ekrana çiz x:\%2\ y:\%3\ ",
    "ZBOT_LED_MATRIX_KAPAT": "ekranı temizle",
    "ZBOT_LED_MATRIX_YAZDIR":"\%1\ ekrana yaz",
    "ZBOT_LED_MATRIX_KAYDIRARAK_YAZDIR": "ekrana \%1\ kaydırarak yaz",
    "ZBOT_LED_YAK_X_Y": "led yak x: \%1\ y:\%2\ ",
    "ZBOT_LED_KAPAT_X_Y": "led söndür x: \%1\ y:\%2\ ",
    "ZBOT_GOZ_KIRP_X_Y": "göz kırp x: \%1\ y:\%2\ ",

    //ZBOT_AYDINLATMA

    "ZBOT_ON_LED_YAK": "ön ışıkları \%1\ yak",
    "ZBOT_ON_LED_KAPAT": "ön ışıkları kapat",
    "ZBOT_ARKA_LEDLERI_YAK": "arka ışıkları \%1\ yak",
    "ZBOT_ARKA_LED_KAPAT": "arka ışıkları kapat",
    "ZBOT_ARKA_LED_SANIYELI": "arka ışıkları \%1\ yak \%2\ saniye",
    "ZBOT_ARKA_LED_RGB_YAP": "arka ışıkları K \%1\ Y \%2\ M \%3\ yap",
    "ZBOT_ARKA_LED_255": "arka ışıkları \%1\ renkte \%2\ yap",

    //ZBOT_HAREKET
    "ZBOT_LINE_FOLLOWER": "siyah çizgiyi takip et",
    "ZBOT_LEFT_RIGHT_CLOCK_SECOND": "\%1\ tekeri \%2\ \%3\ saniye çalıştır",
    "ZBOT_LEFT_RIGHT_CLOCK_POWER":  "\%1\ tekeri \%2\ \%3\ güçte",
    "ZBOT_FORWARD_SECOND": "ileri git \%1\ güçte \%2\ saniye",
    "ZBOT_BACKWARD_SECOND": "geri git \%1\ güçte \%2\ saniye",
    "ZBOT_TURNLEFT_UNTIL": "sola dön \%1\ \%2\ derece",
    "ZBOT_TURNRIGHT_UNTIL": "sağa dön \%1\ \%2\ derece",
    "ZBOT_HAREKET_MENU_GIT": "\%1\ git \%2\ güçte",
    "ZBOT_HAREKET_SOL_SAG_GUC": "sağ teker \%1\ % güçte,sol teker\%2\ % güçte git",
    "ZBOT_HAREKET_DURDUR": "hareketi durdur",


    // ZBOT_EĞLENCE

    "ZBOT_DUYGU": "\%1\ duygu",
    "ZBOT_DANCE": "\%1\ dans gösterisi",
    "ZBOT_SONG_SHOW": "\%1\ şarkı gösterisi",
    "ZBOT_LIGHT_SHOW": "\%1\ ışık gösterisi",


    //ZBOT_OLAYLAR

    "ZBOT_WHEN_SHAKE_ROBOT": "zBot sallandığında",
    "ZBOT_WHEN_TOUCH_ROBOT": "zBot'a dokunduğumda",
    "ZBOT_WHEN_ROBOT_RUN": "zBot açıldığında",
    "ZBOT_DETECT_SOUND_INTENSITY_VERTICAL": "\%1\ şiddeti \%2\ değerinden büyükse",
    "ZBOT_DETECT_LIGHT_VERTICAL": "\%1\ şiddeti > \%2\ değerinden büyükse",
    "ZBOT_WHEN_DETECT_COLOR_VERTICAL": "zBot \%1\ rengi algıladığında",
    "ZBOT_WHEN_DETECT_TILTED_VERTICAL": "zBot \%1\ olduğunda",
    "ZBOT_WHEN_DETECT_SENSOR_VERTICAL": "zBot \%1\ rengi algılandığında",
    "ZBOT_WHEN_DETECT_ROBOT_BUTTON": "\%1\ tuşuna basıldığında",
    "ZBOT_WHEN_DETECT_BARRIER_VERTICAL": "zBot'un önünde engel \%1\ ",
    "ZBOT_WHEN_KEYPRESS_GAME_VERTICAL": "\%1\ tuşuna basıldığında",


    "EVENTS_INFO0_WHENFLAGCLICKED": "Sanal Başlatma Olay Bloğu",
    "EVENTS_INFO0_CLICKPLAY": "Tuş Algılama Bloğu",
    "EVENTS_INFO0_SHAKEROBOT": "Sallantı Algılama Bloğu",
    "EVENTS_INFO0_TOUCHROBOT": "Dokunma Algılama Bloğu",
    "EVENTS_INFO0_DETECETSOUND": "Ses Şiddeti Algılama Bloğu",
    "EVENTS_INFO0_DETECT_SOUND_OFF": "Ses Yoksa Bloğu",
    "EVENTS_INFO0_DETECT_LIGHT": "Işık Şiddeti Algılama Bloğu",
    "EVENTS_INFO0_DETECT_LIGHT_OFF": "Işık Yoksa Bloğu",
    "EVENTS_INFO0_DETECT_COLOR": "Renk Kartları Algılama Bloğu",
    "EVENTS_INFO0_DETECT_KEYPRESS": "Tuş Algılama Bloğu",
    "EVENTS_INFO0_DETECT_BARRIER": "Engel Algılama Bloğu",
    "EVENTS_INFO0_WHENBROADCASTRECEIVE": "Haber Alma Bloğu",
    "EVENTS_INFO0_WHENBROADCAST": "Haber Gönderme Bloğu",
    
    
    "MOVE_INFO0_HAREKET_ILERI": "İleri Git Bloğu",
    "MOVE_INFO0_HAREKET_GERI": "Geri Git Bloğu",
    "MOVE_INFO0_HAREKET_ILERI_SONSUZ": "Sınırsız İleri Adım Bloğu",
    "MOVE_INFO0_HAREKET_GERI_SONSUZ": "Sınırsız Geri Adım Bloğu",
    "MOVE_INFO0_HAREKET_SAGA_DON": "Sağa Dönüş Bloğu",
    "MOVE_INFO0_HAREKET_SOLA_DON": "Sola Dönüş Bloğu",
    "MOVE_INFO0_HAREKET_DUR": "Hareketi Durdurma Bloğu",
    "MOVE_INFO0_HAREKET_SPEED": "Hız Belirleme Bloğu",
    "MOVE_INFO0_HAREKET_CIZGI_IZLEME": "Çizgi Takip Bloğu",
    
    
    "CONTROL_INFO0_REPEAT": "Sayılı Tekrar Bloğu",
    "CONTROL_INFO0_CONTROL_FOREVER": "Sürekli Tekrarla Bloğu",
    "CONTROL_INFO0_CONTROLREPEAT": "Sayılı Tekrar Bloğu",
    "CONTROL_INFO0_CONTROLSTOPHORIZONTAL": "Durdurma Bloğu",
    "CONTROL_INFO0_CONTROLWAIT": "Bekleme Bloğu",
    
    
    
    
    
    "LIGHTS_INFO0_LIGHTON": "Ön Işıkları Yakma Bloğu",
    "LIGHTS_INFO0_LIGHTOFF":"Ön Işık Kapatma Bloğu",
    "LIGHTS_INFO0_LIGHTSOFF": "Arka Led Kapatma Bloğu",
    "LIGHTS_INFO0_LIGHTS": "Arka Işıkları Yakma Bloğu",
    "LIGHTS_INFO0_LIGHTONOFF": "Ön Işıkları Yakma Bloğu",
    
    
    "LOOK_INFO0_LEDMATRIX": "Ekrana Şekil Çizdirme Bloğu",
    "LOOK_INFO0_LEDOFF": "Ekranı Kapatma Bloğu",
    "LOOK_INFO0_LEDTEXT": "Ekrana Yazdırma Bloğu",
    "LOOK_INFO0_HEART_EMOTION": "Ekrana Şekil Çizdirme Bloğu",
    
    
    
    "SOUND_INFO0_MELODILIST": " Nota Çalma Bloğu",
    "SOUND_INFO0_MELODI": "Nota Seviyesi Seçme Bloğu",
    "SOUND_INFO0_SOUND_OFF": "Ses Kapatma Bloğu",
    "SOUND_INFO0_CUSTOMSOUND": "Hazır Sesleri Çalma Bloğu",
    "SOUND_INFO0_PLAYSOUND": "Ses Seviyesi Ayarlama Bloğu",
    
    
    
    
    
    "SOUND_INFO1_MELODILIST": "zBot’un seçilen notayı çalmasını sağlar.",
    "SOUND_INFO1_MELODI": "zBot’un seçilen nota seviyesinde çalmasını sağlar.",
    "SOUND_INFO1_SOUND_OFF": "zBot’un sesini kapatmak için kullanılır.",
    "SOUND_INFO1_CUSTOMSOUND": "Seçilen sesin çalınmasını sağlar.",
    "SOUND_INFO1_PLAYSOUND": "zBot’un ses seviyesini ayarlamak için kullanılır.",
    
    "LOOK_INFO1_LEDMATRIX": "zBot’un ekranına serbest çizim yapmak için kullanılır.",
    "LOOK_INFO1_LEDOFF": "zBot’un ekranını kapatmak için kullanılır.",
    "LOOK_INFO1_LEDTEXT": "zBot’un ekranına yazı yazmak için kullanılır.",
    "LOOK_INFO1_HEART_EMOTION": "zBot’un ekranına seçilen şekli çizdirir.",
    
    
    "LIGHTS_INFO1_LIGHTON": "zBot’un öndeki ışıklarını seçilen renkte yakar.",
    "LIGHTS_INFO1_LIGHTOFF": "zBot’un ön ışıklarını kapatır.",
    "LIGHTS_INFO1_LIGHTSOFF": "zBot’un arka ışıklarını kapatır.",
    "LIGHTS_INFO1_LIGHTS": "zBot’un arka ışıklarını seçilen renkteki yakar.",
    "LIGHTS_INFO1_LIGHTONOFF": "Sarı renkli play butonuna tıklanınca entegre bloklar çalışır.",
    
    
    "CONTROL_INFO1_REPEAT": "Belli sayıda tekrar etmesi gereken kod blokları için kullanılır.",
    "CONTROL_INFO1_CONTROL_FOREVER": "Sürekli tekrar etmesi gereken kod blokları için kullanılır.",
    "CONTROL_INFO1_CONTROLREPEAT": "Belli sayıda tekrar etmesi gereken kod blokları için kullanılır.",
    "CONTROL_INFO1_CONTROLSTOPHORIZONTAL": "Tüm kodları durdurur.",
    "CONTROL_INFO1_CONTROLWAIT": "Belirli bir saniye beklemek için kullanılır.",
    
    
    "MOVE_INFO1_HAREKET_ILERI": " zBot belirtilen adım kadar ileri gider.",
    "MOVE_INFO1_HAREKET_GERI": "zBot belirtilen adım kadar geri gider.",
    "MOVE_INFO1_HAREKET_ILERI_SONSUZ": "zBot durdurulana kadar ileri hareket eder.",
    "MOVE_INFO1_HAREKET_GERI_SONSUZ": "zBot durdurulana kadar geri hareket eder.",
    "MOVE_INFO1_HAREKET_SAGA_DON": "zBot belirtilen sayıda 90 derece sağa döner.",
    "MOVE_INFO1_HAREKET_SOLA_DON": "zBot belirtilen sayıda 90 derece sola döner.",
    "MOVE_INFO1_HAREKET_DUR": "zBot’un hareketini durdurur.",
    "MOVE_INFO1_HAREKET_SPEED": "zBot’un motor dönüş hızını belirler.",
    "MOVE_INFO1_HAREKET_CIZGI_IZLEME": "zBot çizgi takibi yapar.",
    
    
    "EVENTS_INFO1_WHENFLAGCLICKED": "Sarı renkli play butonuna tıklanınca entegre bloklar çalışır.",
    "EVENTS_INFO1_CLICKPLAY": "zBot üzerindeki tuşları alıgalayıp ilgili kodları çalıştırır.",
    "EVENTS_INFO1_SHAKEROBOT": " zBot sallandığında kodları çalıştırır.",
    "EVENTS_INFO1_TOUCHROBOT": "zBot’a dokunulduğunda kodları çalıştırır.",
    "EVENTS_INFO1_DETECETSOUND": "zBot’un belirlenen seviyede ses algıladığında kodları çalıştırır.",
    "EVENTS_INFO1_DETECT_SOUND_OFF": "zBot herhangi bir ses algılamadığında kodları çalıştırır.",
    "EVENTS_INFO1_DETECT_LIGHT": "zBot belirlenen seviyede ışık algıladığında kodları çalıştırır.",
    "EVENTS_INFO1_DETECT_LIGHT_OFF": "zBot herhangi bir ışık algılamadığında kodları çalıştırır.",
    "EVENTS_INFO1_DETECT_COLOR": "zBot belirtilen renk kartı gösterildiğinde kodları çalıştırır.",
    "EVENTS_INFO1_DETECT_KEYPRESS": "zBot üzerindeki tuşları alıgalayıp ilgili kodları çalıştırır.",
    "EVENTS_INFO1_DETECT_BARRIER": "zBot engel gördüğünde veya engel kalktığında kodları çalıştırır.",
    "EVENTS_INFO1_WHENBROADCASTRECEIVE": " Belirlenen bir olay başladı haberi alındığında kod bloklarını çalıştırır.",
    "EVENTS_INFO1_WHENBROADCAST": "Belirlenen bir olayın başladığı haberinin vermek için kullanılır.",










    //ZTRONİC SOKET
    "SOKET1": "soket-1",
    "SOKET2": "soket-2",
    "SOKET3": "soket-3",
    "SOKET4": "soket-4",
    "SOKET5": "soket-5",
    "SOKET6": "soket-6",
  
    "X": "x",
    "CIRCLE": "daire",
    "SQUARE": "kare",
    "TRIANGLE": "ücgen",


    //ZTORNİC_OLAYLAR
    "ZTRONIC_WHEN_ROBOT_RUN": "zBot açıldığında",
    "ZTRONIC_DETECT_LIGHT_VERTICAL": "\%1\ > \%2\ değerinden büyükse",
    "ZTRONIC_WHEN_DETECT_ROBOT_BUTTON": "\%1\ tuşuna basıldığında",
    "ZTRONIC_WHEN_SHAKE_ROBOT": "zBot sallandığında",
    "ZTRONIC_WHEN_DETECT_TILTED_VERTICAL": "zTronic \%1\ olduğunda",
    "ZTRONIC_WHEN_TOUCH_ROBOT": "zBot'a dokunduğumda",
    "ZTRONIC_DETECT_BURN": "ateş aldılandığında \%1\ ",
    "ZTRONIC_DETECT_PIR": "pir aldılandığında \%1\ ",
    "ZTRONIC_DETECT_POT": "potansiyometre \%1\ değerinden büyükse",
    "ZTRONIC_DETECT_ULTRASONIC": "ultrasonik \%1\ değerinden büyükse",
    "ZTRONIC_DETECT_FLAME": "flame aldılandığında \%1\ ",

    //ZTRONİC HAREKET
    "ZTRONIC_DC_MOTOR_SANIYE": "dc motoru \%1\ soketinde \%2\ sn çalıştır ",
    "ZTRONIC_DC_MOTOR_GUCTE":  "dc motoru \%1\ soketinde \%2\ güçte",
    "ZTRONIC_DC_MOTOR_GUCTE_SANIYE": "dc motoru \%1\ soketinde \%2\ güçte \%3\ sn",
    "ZTRONIC_DC_MOTOR_DURDUR": "dc motoru durdur",
    "ZTRONIC_SERVO_MOTOR_GUCTE": "servo motoru \%1\ soketinde \%2\ yönünde \%3\ güçte",
    "ZTRONIC_SERVO_MOTOR_SUREKLI": "servo motoru \%1\ soketinde \%2\ yönünde ",
    "ZTRONIC_SERVO_MOTOR_DURDUR": "servo motoru durdur",

    //ZTRONİC RGB LED YAK
    "ZTRONIC_RGB_LED_YAK": "RGB ledleri \%1\ yak", 
    "ZTRONIC_RGB_LED_YAK_SANIYELI": "RGB ledleri \%1\ yak \%2\ saniye",
    "ZTRONIC_RGB_LED_YAK_SANIYELI_SECMELI": " RGB \%1\ \%2\ yak \%3\ saniye", 
    "ZTRONIC_ISIK_GOSTERISI": "\%1\ gösterisi",
    "ZTRONIC_RGB_LED_KAPAT": "RGB ledleri kapat",

    //ZTRONIC_LEDMATRIX
    "ZTRONIC_ZZ_LED_MATRIX": "\%1\ OLED ekrana Çiz",
    "ZTRONIC_ZZ_LED_MATRIX_SECMELI": "\%1\ çizim seç",
    "ZTRONIC_LED_MATRIX_KAYDIRARAK_YAZDIR": "\%1\ OLED ekrana yaz",
    "ZTRONIC_ZZ_LED_MATRIX_KAPAT": "OLED Ekranı Kapat",


     //ZTRONIC_SENSORS
     "ZTRONIC_HAREKET_ALGILAMA": "Hareket Algılandı (PIR)",
     "ZTRONIC_ALEV_ALGILAMA": "Alev Algılandı (Flame)",
     "ZTRONIC_SALLANDI": "Ztronic sallandı",
     "ZTRONIC_TUS_ALGILAMA": "\%1\ ileri tuşuna basıldı",
     "ZTRONIC_SES_SİDDETI": "ses şiddeti",
     "ZTRONIC_SALLANMA_SİDDETI": "sallanma şiddeti",
     "ZTRONIC_ISIK_SİDDETI": "ışık şiddeti",
     "ZTRONIC_PIL_SEVIYESI": "pil seviyesi",
     "ZTRONIC_EGIM_ACISI": "eğim açısı",
     "ZTRONIC_YATIS_ACISI": "yatış açısı",
     "ZTRONIC_SICAKLIK": "sıcaklık",
     "ZTRONIC_NEM": "nem",
     "ZTRONIC_TOPRAK_NEMI": "toprak nemi",
     "ZTRONIC_POTANSIYOMETRE": "potansiyometre",
     "ZTRONIC_MESAFE": "mesafe",
   

};

Blockly.ScratchMsgs.locales["en"] =
{
    "CONTROL_FOREVER": "forever",
    "CONTROL_REPEAT": "repeat %1",
    "CONTROL_IF": "if %1 then",
    "CONTROL_ELSE": "else",
    "CONTROL_STOP": "stop",
    "CONTROL_STOP_ALL": "all",
    "CONTROL_STOP_THIS": "this script",
    "CONTROL_STOP_OTHER": "other scripts in sprite",
    "CONTROL_WAIT": "wait %1 seconds",
    "CONTROL_WAITUNTIL": "wait until %1",
    "CONTROL_REPEATUNTIL": "repeat until %1",
    "CONTROL_WHILE": "while %1",
    "CONTROL_FOREACH": "for each %1 in %2",
    "CONTROL_STARTASCLONE": "when I start as a clone",
    "CONTROL_CREATECLONEOF": "create clone of %1",
    "CONTROL_CREATECLONEOF_MYSELF": "myself",
    "CONTROL_DELETETHISCLONE": "delete this clone",
    "CONTROL_COUNTER": "counter",
    "CONTROL_INCRCOUNTER": "increment counter",
    "CONTROL_CLEARCOUNTER": "clear counter",
    "CONTROL_ALLATONCE": "all at once",
    "DATA_SETVARIABLETO": "set %1 to %2",
    "DATA_CHANGEVARIABLEBY": "change %1 by %2",
    "DATA_SHOWVARIABLE": "show variable %1",
    "DATA_HIDEVARIABLE": "hide variable %1",
    "DATA_ADDTOLIST": "add %1 to %2",
    "DATA_DELETEOFLIST": "delete %1 of %2",
    "DATA_DELETEALLOFLIST": "delete all of %1",
    "DATA_INSERTATLIST": "insert %1 at %2 of %3",
    "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
    "DATA_ITEMOFLIST": "item %1 of %2",
    "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
    "DATA_LENGTHOFLIST": "length of %1",
    "DATA_LISTCONTAINSITEM": "%1 contains %2?",
    "DATA_SHOWLIST": "show list %1",
    "DATA_HIDELIST": "hide list %1",
    "DATA_INDEX_ALL": "all",
    "DATA_INDEX_LAST": "last",
    "DATA_INDEX_RANDOM": "random",
    "EVENT_WHENARDUINOBEGIN": "when Pybot begin",
    "EVENT_WHENMICROBITBEGIN": "when Microbit begin",
    "EVENT_WHENMICROBITBUTTONPRESSED": "when button %1 pressed",
    "EVENT_WHENMICROBITPINBEINGTOUCHED": "when pin %1 being touched",
    "EVENT_WHENMICROBITGESTURE": "when the gestrue %1",
    "EVENT_WHENFLAGCLICKED": "when %1 clicked",
    "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
    "EVENT_WHENSTAGECLICKED": "when stage clicked",
    "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
    "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
    "EVENT_BACKDROP1": "dekor 1",
    "EVENT_WHENGREATERTHAN": "when %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "timer",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
    "EVENT_BROADCAST": "broadcast %1",
    "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
    "EVENT_WHENKEYPRESSED": "when %1 key pressed",
    "EVENT_WHENMICROBITGESTURE_SHAKEN": "shaken",
    "EVENT_WHENMICROBITGESTURE_TILTEDUPWARD": "tilted upward",
    "EVENT_WHENMICROBITGESTURE_TILTEDDOWNWARD": "tilted downward",
    "EVENT_WHENMICROBITGESTURE_TILTEDDLEFTWARD": "tilted leftward",
    "EVENT_WHENMICROBITGESTURE_TILTEDDRIGHTWARD": "tilted rightward",
    "EVENT_WHENMICROBITGESTURE_FACEUP": "face up",
    "EVENT_WHENMICROBITGESTURE_FACEDOWN": "face down",
    "EVENT_WHENMICROBITGESTURE_FREEFALL": "freefall",
    "EVENT_WHENKEYPRESSED_SPACE": "space",
    "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
    "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
    "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
    "EVENT_WHENKEYPRESSED_UP": "up arrow",
    "EVENT_WHENKEYPRESSED_ANY": "any",
    "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
    "LOOKS_SAY": "say %1",
    "LOOKS_HELLO": "Hello!",
    "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
    "LOOKS_THINK": "think %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "show",
    "LOOKS_HIDE": "hide",
    "LOOKS_HIDEALLSPRITES": "hide all sprites",
    "LOOKS_EFFECT_COLOR": "color",
    "LOOKS_EFFECT_FISHEYE": "fisheye",
    "LOOKS_EFFECT_WHIRL": "whirl",
    "LOOKS_EFFECT_PIXELATE": "pixelate",
    "LOOKS_EFFECT_MOSAIC": "mosaic",
    "LOOKS_EFFECT_BRIGHTNESS": "brightness",
    "LOOKS_EFFECT_GHOST": "ghost",
    "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
    "LOOKS_SETEFFECTTO": "set %1 effect to %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
    "LOOKS_CHANGESIZEBY": "change size by %1",
    "LOOKS_SETSIZETO": "set size to %1 %",
    "LOOKS_SIZE": "size",
    "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
    "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
    "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
    "LOOKS_NEXTCOSTUME": "next costume",
    "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
    "LOOKS_GOTOFRONTBACK": "go to %1 layer",
    "LOOKS_GOTOFRONTBACK_FRONT": "front",
    "LOOKS_GOTOFRONTBACK_BACK": "back",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
    "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
    "LOOKS_COSTUMENUMBERNAME": "costume %1",
    "LOOKS_NUMBERNAME_NUMBER": "number",
    "LOOKS_NUMBERNAME_NAME": "name",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
    "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
    "LOOKS_NEXTBACKDROP": "next backdrop",
    "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
    "LOOKS_RANDOMBACKDROP": "random backdrop",
    "MOTION_MOVESTEPS": "move %1 steps",
    "MOTION_TURNLEFT": "turn %1 %2 degrees",
    "MOTION_TURNRIGHT": "turn %1 %2 degrees",
    "MOTION_POINTINDIRECTION": "point in direction %1",
    "MOTION_POINTTOWARDS": "point towards %1",
    "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
    "MOTION_POINTTOWARDS_RANDOM": "random direction",
    "MOTION_GOTO": "go to %1",
    "MOTION_GOTO_POINTER": "mouse-pointer",
    "MOTION_GOTO_RANDOM": "random position",
    "MOTION_GOTOXY": "x: %1 y: %2 konumuna ilerle",
    "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
    "MOTION_GLIDETO": "glide %1 secs to %2",
    "MOTION_GLIDETO_POINTER": "mouse-pointer",
    "MOTION_GLIDETO_RANDOM": "random position",
    "MOTION_CHANGEXBY": "change x by %1",
    "MOTION_SETX": "set x to %1",
    "MOTION_CHANGEYBY": "change y by %1",
    "MOTION_SETY": "set y to %1",
    "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
    "MOTION_SETROTATIONSTYLE": "set rotation style %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
    "MOTION_XPOSITION": "x position",
    "MOTION_YPOSITION": "y position",
    "MOTION_DIRECTION": "direction",
    "MOTION_SCROLLRIGHT": "scroll right %1",
    "MOTION_SCROLLUP": "scroll up %1",
    "MOTION_ALIGNSCENE": "align scene %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
    "MOTION_ALIGNSCENE_MIDDLE": "middle",
    "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
    "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
    "MOTION_XSCROLL": "x scroll",
    "MOTION_YSCROLL": "y scroll",
    "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "pick random %1 to %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 and %2",
    "OPERATORS_OR": "%1 or %2",
    "OPERATORS_NOT": "not %1",
    "OPERATORS_JOIN": "join %1 %2",
    "OPERATORS_JOIN_APPLE": "apple",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "letter %1 of %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "length of %1",
    "OPERATORS_CONTAINS": "%1 contains %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "round %1",
    "OPERATORS_MATHOP": "%1 of %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "floor",
    "OPERATORS_MATHOP_CEILING": "ceiling",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "define %1",
    "SENSING_TOUCHINGOBJECT": "touching %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
    "SENSING_TOUCHINGOBJECT_EDGE": "edge",
    "SENSING_TOUCHINGCOLOR": "touching color %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
    "SENSING_DISTANCETO": "distance to %1",
    "SENSING_DISTANCETO_POINTER": "mouse-pointer",
    "SENSING_ASKANDWAIT": "ask %1 and wait",
    "SENSING_ASK_TEXT": "What's your name?",
    "SENSING_ANSWER": "answer",
    "SENSING_KEYPRESSED": "key %1 pressed?",
    "SENSING_MOUSEDOWN": "mouse down?",
    "SENSING_MOUSEX": "mouse x",
    "SENSING_MOUSEY": "mouse y",
    "SENSING_SETDRAGMODE": "set drag mode %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
    "SENSING_LOUDNESS": "loudness",
    "SENSING_LOUD": "loud?",
    "SENSING_TIMER": "timer",
    "SENSING_RESETTIMER": "reset timer",
    "SENSING_OF": "%1 of %2",
    "SENSING_OF_XPOSITION": "x position",
    "SENSING_OF_YPOSITION": "y position",
    "SENSING_OF_DIRECTION": "direction",
    "SENSING_OF_COSTUMENUMBER": "costume #",
    "SENSING_OF_COSTUMENAME": "costume name",
    "SENSING_OF_SIZE": "size",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "backdrop #",
    "SENSING_OF_BACKDROPNAME": "backdrop name",
    "SENSING_OF_STAGE": "Stage",
    "SENSING_CURRENT": "current %1",
    "SENSING_CURRENT_YEAR": "year",
    "SENSING_CURRENT_MONTH": "month",
    "SENSING_CURRENT_DATE": "date",
    "SENSING_CURRENT_DAYOFWEEK": "day of week",
    "SENSING_CURRENT_HOUR": "hour",
    "SENSING_CURRENT_MINUTE": "minute",
    "SENSING_CURRENT_SECOND": "second",
    "SENSING_DAYSSINCE2000": "days since 2000",
    "SENSING_USERNAME": "username",
    "SENSING_USERID": "user id",
    "SOUND_PLAY": "start sound %1",
    "SOUND_PLAYUNTILDONE": "play sound %1 until done",
    "SOUND_STOPALLSOUNDS": "stop all sounds",
    "SOUND_SETEFFECTO": "set %1 effect to %2",
    "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
    "SOUND_CLEAREFFECTS": "clear sound effects",
    "SOUND_EFFECTS_PITCH": "pitch",
    "SOUND_EFFECTS_PAN": "pan left/right",
    "SOUND_CHANGEVOLUMEBY": "change volume by %1",
    "SOUND_SETVOLUMETO": "set volume to %1%",
    "SOUND_VOLUME": "volume",
    "SOUND_RECORD": "record...",
    "CATEGORY_MOTION": "Motion",
    "CATEGORY_LOOKS": "Looks",
    "CATEGORY_SOUND": "Sound",
    "CATEGORY_EVENTS": "Events",
    "CATEGORY_ENTERTAINMENT": "Fun",
    "CATEGORY_CONTROL": "Control",
    "CATEGORY_SENSING": "Sensing",
    "CATEGORY_OPERATORS": "Operators",
    "CATEGORY_VARIABLES": "Variables",
    "CATEGORY_MYBLOCKS": "My Blocks",
    "CATEGORY_LIGHTING": "Lights",
    "CATEGORY_SPEAKER": "Speaker",
    "CATEGORY_NETWORK": "Network",
    "DUPLICATE": "Duplicate",
    "DELETE": "Delete",
    "ADD_COMMENT": "Add Comment",
    "REMOVE_COMMENT": "Remove Comment",
    "DELETE_BLOCK": "Delete Block",
    "DELETE_X_BLOCKS": "Delete %1 Blocks",
    "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
    "CLEAN_UP": "Clean up Blocks",
    "HELP": "Help",
    "UNDO": "Undo",
    "REDO": "Redo",
    "EDIT_PROCEDURE": "Edit",
    "SHOW_PROCEDURE_DEFINITION": "Go to definition",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
    "COLOUR_HUE_LABEL": "Color",
    "COLOUR_SATURATION_LABEL": "Saturation",
    "COLOUR_BRIGHTNESS_LABEL": "Brightness",
    "CHANGE_VALUE_TITLE": "Change value:",
    "RENAME_VARIABLE": "Rename variable",
    "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
    "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
    "NEW_VARIABLE": "Make a Variable",
    "NEW_VARIABLE_TITLE": "New variable name:",
    "VARIABLE_MODAL_TITLE": "New Variable",
    "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
    "DELETE_VARIABLE": "Delete the \"%1\" variable",
    "NEW_PROCEDURE": "Make a Block",
    "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
    "PROCEDURE_DEFAULT_NAME": "block name",
    "PROCEDURE_USED": "To delete a block definition, first remove all uses of the block",
    "NEW_LIST": "Make a List",
    "NEW_LIST_TITLE": "New list name:",
    "LIST_MODAL_TITLE": "New List",
    "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
    "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
    "RENAME_LIST_MODAL_TITLE": "Rename List",
    "DEFAULT_LIST_ITEM": "thing",
    "DELETE_LIST": "Delete the \"%1\" list",
    "RENAME_LIST": "Rename list",
    "NEW_BROADCAST_MESSAGE": "New message",
    "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
    "BROADCAST_MODAL_TITLE": "New Message",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
    "OLED_CATEGORY":"OLED",
    "OLED_INIT":"\%1\ \%2\ init oled",
    "OLED_DRAWLINE":"\%1\ \%2\ draw line x0: \%3\ y0: \%4\, x1: \%5\ y1：\%6\ color \%7\ ",
    "OLED_COLOR_WHITE":"white",
    "OLED_COLOR_BLACK":"black",
    "OLED_COLOR_INVERSE":"inverse",
    "OLED_DRAWRECT":"\%1\ \%2\ draw rect x: \%3\ y: \%4\ width \%5\ height \%6\ color \%7\ ",
    "OLED_FILLRECT":"\%1\ \%2\ fill rect x: \%3\ y: \%4\ width \%5\ height \%6\ color \%7\ ",
    "OLED_DRAWCIRCLE":"\%1\ \%2\ draw circle x: \%3\ y: \%4\ radius \%5\ color \%6\ ",
    "OLED_FILLCIRCLE":"\%1\ \%2\ fill circle x: \%3\ y: \%4\ radius \%5\ color \%6\ ",
    "OLED_DRAWROUNDRECT":"\%1\ \%2\ draw round rect x: \%3\ y: \%4\ width \%5\ height \%6\ radius \%7\ color \%8\ ",
    "OLED_FILLROUNDRECT":"\%1\ \%2\ fill round rect x: \%3\ y: \%4\ width \%5\ height \%6\ radius \%7\ color \%8\ ",
    "OLED_DRAWTRIANGLE":"\%1\ \%2\ draw triangle x0: \%3\ y0: \%4\, x1: \%5\ y1: \%6\, x2: \%7\ y2: \%8\ color \%9\ ",
    "OLED_FILLTRIANGLE":"\%1\ \%2\ fill triangle x0: \%3\ y0: \%4\, x1: \%5\ y1: \%6\, x2: \%7\ y2: \%8\ color \%9\ ",
    "OLED_SETTEXT":"\%1\ \%2\ set text size \%3\ color \%4\ background color \%5\ ",
    "OLED_SETCURSOR":"\%1\ \%2\ set cursor x: \%3\ y: \%4\ ",
    "OLED_PRINT":"\%1\ \%2\ print \%3\ \%4\ ",
    "OLED_EOL_WARP":"warp",
    "OLED_EOL_NOWARP":"no-warp",
    "OLED_CLEAR":"\%1\ \%2\ clear oled",
    "OLED_REFRESH":"\%1\ \%2\ refresh oled",
    "OLED_STARTSCROLL":"\%1\ \%2\ start scroll \%3\ y0: \%4\ y1: \%5\ ",
    "OLED_SCROLL_RIGHT":"right",
    "OLED_SCROLL_LEFT":"left",
    "OLED_SCROLL_DIAGRIGHT":"diag right",
    "OLED_SCROLL_DIAGLEFT":"diag left",
    "OLED_STOPSCROLL":"\%1\ \%2\ stop scroll",
    "RGBLEDSTRIP_INIT":"init RGB LED strip length \%1\ pin \%2\ ",
    "RGBLEDSTRIP_SETPIXELCOLOR":"\%1\ \%2\ set pixel \%3\ color \%4\ ",
    "RGBLED_LIGHTSUP":"\%1\ \%2\ RGB LED lights up \%3\ ",
    "RGBLEDSTRIP_FILL":"\%1\ \%2\ fill from pixel \%3\ count \%4\ with color \%5\ ",
    "RGBLEDSTRIP_COLOR":"\%1\ \%2\ RGB LED strip color R \%3\ G \%4\ B \%5\ ",
    "RGBLEDSTRIP_SETBRIGHTNESS":"\%1\ \%2\ set brightness \%3\ ",
    "RGBLEDSTRIP_CLEAR":"\%1\ \%2\ clear all",
    "RGBLEDSTRIP_SHOW":"refresh",
    "RGBLED_YAK" : '\%1\ \%2\ RGB Led Light \%3\ \%4\ \%5\ ',
    "RGBLED_SONDUR": '\%1\ \%2\ Turn off all leds',

    "RGB_LED_YAK_SURELI_PYBOARD" : '\%1\ \%2\ Led lights up \%3\ color \%4\ seconds \%5\ socket \%6\ ',
    "RGB_LED_YAK_SURESIZ_PYBOARD" : '\%1\ \%2\ Led \%3\ color \%4\ lights up socket \%5\ ',
    "RGB_LED_KAPAT_PYBOARD" : "\%1\ \%2\ Led \%3\ led turn off socket \%4\ ",
    "RGBLED_YAK_PYBOARD" : '\%1\ \%2\ RGB Led Light \%3\  \%4\  \%5\  socket \%6\ ',
    "RGBLED_SONDUR_PYBOARD" : '\%1\ \%2\ Turn off all leds socket \%3\ ',




    "SES_CAL":"\%1\ \%2\ play sound \%3\ ",
    "SES_CAL_BITENE_KADAR":"\%1\ \%2\ play sound \%3\ until",
    "TUM_SESLERI_DURDUR":"\%1\ \%2\ stop all sounds",
    "NOTA_CAL":"\%1\ \%2\ play note \%3\ for \%4\ beats",
    "SUS_VURUS":"\%1\ \%2\ rest for \%3\ vuruş",
    "FREKANSDA_CAL":"\%1\ \%2\  play sound at frequency of \%3\ HZ for \%4\ secs",
    "SES_SEVIYESINI_DEGİSTİR":"\%1\ \%2\ change volume by \%3\ ",
    "SES_SEVIYESI_YUZDE":"\%1\ \%2\ set volume \%3\ %",
    "SES_SEVIYESI":"\%1\ \%2\ volume",

    "ILERI_GIT":"\%1\ \%2\ Go forward \%3\ % power \%4\ secs",
    "GERI_GIT":"go backward \%1\ % power \%2\ secs",
    "SOLA_GIT":"\%1\ \%2\ turn left \%3\ % power \%4\ secs",
    "SAGA_GIT":"\%1\ \%2\ turn right \%3\ % power \%4\ secs",
    "GERIYE_GIT":"\%1\ \%2\ go backward \%3\ % power \%4\ secs",
    "SOLA_DON_DERECE_BITIMI":"\%1\ \%2\ turn left  \%3\ degrees until done",
    "SAGA_DON_DERECE_BITIMI":"\%1\ \%2\ turn right \%3\ degrees until done",
    "SEC_GIT":"\%1\ \%2\ \%3\ \%4\ % power",
    "SEC_GIT_RPM":"\%1\ \%2\ \%3\ \%4\ % rpm",
    "SOLA_SAGA_DERECELI_GIT_RPM":"\%1\ \%2\ left wheels turns at  rpm \%3\   right wheels turns at  rpm % \%4\ ",
    "SOLA_SAGA_DERECELI_GIT_POWER":"\%1\ \%2\ left wheels turns at power \%3\ %  right wheels turns at power \%4\ ",
    "HAREKETI_DURDUR":"\%1\ \%2\ stop moving \%3\ ",
    "RESET_ENCODER_MOTOR":"\%1\ \%2\ \%3\ reset motors",
    "MOTOR_MOVES_CM":"\%1\ \%2\ hareket \%3\ rotates at \%4\ go up cm",
    "MOTOR_ROTATES_AT_TIME":"\%1\ \%2\ encoder motor \%3\" rotates at \%4\ \%5\ rpm \%6\ secs",
    "MOTOR_ACIYA_GORE_DON":"\%1\ \%2\ encoder motor \%3\  rotates at \%4\ angle",
    "MOTOR_ROTATED_ANGLE":"\%1\ \%2\ encoder motor \%3\ at \%4\ angle",
    "MOTOR_ACI":"\%1\ \%2\ encoder motor \%3\ at \%4\ secs",
    "RPM_SANIYE_YON":"\%1\ \%2\ encoder motor \%3\ at \%4\ rpm \%5\ secs",
    "GUC_SANIYE_YON":"\%1\ \%2\ encoder motor \%3\ at \%4\ power \%5\ secs",
    "MOTOR_ROTATES_AT":"\%1\ \%2\ encoder motor \%3\ rotates at \%4\ \%5\ ",

    "DUGME_BASILDIMI":"\%1\ \%2\ buton is pressed?",
    "CIHAZ_BAGLANDIMI":"\%1\ \%2\ when device connected?",
    "POTANSIYOMETRE_DEGERI":"\%1\ \%2\ gear potentiometer value",
    "PIL_SEVIYESI":"battery level",
    "CIHAZ_SALLANIYORMU":"shaken?",
    "SALLANMA_SIDDETI":"shaking strength",
    "CIHAZ_EGIKMI":"\%1\ \%2\ device \%3\ tilted?",
    "CIHAZ_KONUMU":"\%1\ \%2\ device \%3\ positioning",
    "YATIS_ACISI":"rool angle",
    "EGIM_ACISI":"pitch angle",
    "X_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ rotation angle around x",
    "Y_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ rotation angle around y",
    "Z_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ rotation angle around z",
    "SIFIRLA_DONDURME_ACISI":"\%1\ \%2\ reset \%3\ rotation angle",
    "ZAMANLAYICI":"\%1\ \%2\ timer",
    "ZAMANLAYICI_SIFIRLA":"\%1\ \%2\ reset timer",
    "ONUNDEKI_ENGELLER":"obstacles ahead",
    "ALGILANAN_RENK":"color value detected \%1\ ?",
    "RENK_DEGER_TESPITI":"\%1\ \%2\  \%3\ color value detected",
    "RENK_ALGILAYICI_ORTAM_ISIK_YOGUNLUGU":"\%1\ \%2\ color sensor ambient light intensity",
    "RENK_ALGILAYICI_YANSIYAN_ISIK_YOGUNLUGU":"\%1\ \%2\ color sensor reflected  light intensity",
    "RENK_ALGILAYICI_YANSIYAN_KIZILOTESI_ISIK_YOGUNLUGU":"\%1\ \%2\ color sensor reflected infrared light intensity",
    "RENK_ALGILAYICI_GRI_OLCEK":"\%1\ \%2\ color sensor grey-scale light intensity",
    "WIFI_CONNECT_SSID":"\%1\ \%2\ connected to  Wİ-Fİ  \%3\ password \%4\ ",
    "WIFI_BAGLANDIMI":"\%1\ \%2\ Wİ-Fİ connected?",
    "CONNECTED_WIFI_AGAIN":"\%1\ \%2\ connected to  Wİ-Fİ again ",
    "DISCONNECTED_WIFI_AGAIN":"\%1\ \%2\ disconnect from Wİ-Fİ ",
    "BUGUNUN_TARIHINI_GETIR":"\%1\ \%2\ \%3\ get  \%4\  time ",
    "RGB_LED_YAK_SURELI": "\%1\ \%2\ Led lights up \%3\ color \%4\ seconds \%5\ ",
    "RGB_LED_YAK_SURESIZ":"\%1\ \%2\ Led \%3\ color \%4\ lights up",
    "RGB_LED_KAPAT" : "\%1\ \%2\ Led \%3\ led turn off",
    "RGB_LED_BASLAT": "\%1\ \%2\ Led init",
    "TEMPATURE_DHT": '\%1\ \%2\ Sıcaklık ve Bağıl nem sensörünü başlat block \%3\ ',
    "MESAFE_VE_ISIK": "\%1\ \%2\ Start distance and light intensity",
    "PROMIXITY_UZAKLIK_SENSORU":"\%1\ \%2\ distance value",
    "DISLI_POTANSIYOMETRI_DEGERI": "\%1\ \%2\ gear potentiometer value \%3\ ",
    "ORTAM_ISIK_SIDDETI":"ambient light intensity",
    "YESIL_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ green light intensity",
    "MAVI_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ blue light intensity",
    "KIRMIZI_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ red light intensity",
    "START_MOTION_SENSOR": "\%1\ \%2\ start motion sensor",
    "START_LINE_FOLLOWER": "\%1\ \%2\ Initialize line tracking sensor",
    "START_LINE_FONKS": "\%1\ \%2\ Read line trace functions \%3\ ",
    "READ_LINE_NEW": "\%1\ \%2\ read line \%3\ \%4\ ",
    "EGIM_ACISI_DURUMLU": "\%1\ \%2\  \%3\  tilt angle",
    "X_Y_Z_ACISI_DURUMLU": "\%1\ \%2\  \%3\ tilt angle",
    "X_Y_Z_HIZ_DURUMLU": "\%1\ \%2\  \%3\ speed m/s^2",
    "BUZER_AC": "\%1\ \%2\ Open Buzzer",
    "BUZER_KAPAT": "\%1\ \%2\ Buzzer Close",
    "FREKANSDA_SES_CAL":"\%1\ \%2\  \%3\ Play audio in Hz frequency \%4\ beats",
    "ILERI_GIT_TEXT":"Go forward",
    "GERI_GIT_TEXT":"go back",
    "SOLA_GIT_TEXT":"turn left",
    "SAGA_GIT_TEXT":"turn right",
    "LEFT_WHELL":"left wheel EM(1)",
    "RIGHT_WHELL":"right wheel EM(2)",
    "ALL_WHELL":"all",
    "RPM_TEXT":"rotationel speed(RPM)",
    "POWER_TEXT":"power(%)",
    "SAG_TEXT":"right",
    "SOL_TEXT":"left",
    "HEPSI_TEXT":"all",
    "KIRMIZI":"red",
    "CAMGOBEGI":"cyan",
    "MAVI":"blue",
    "YESIL":"green",
    "SARI":"yellow",
    "BEYAZ":"white",
    "SIYAH":"black",
    "GALIBARDA":"galibarda",
    "ONE_EGIK":"leaning forward",
    "GERIYE_EGIK":"tilted backwards",
    "SOLA_EGIK":"tilted left",
    "SAGA_EGIK":"tilted right",
    "YUZU_YUKARI_DONUK":"face up",
    "YUZU_ASAGI_DONUK":"face down",
    "ILERI_EGIK_TEXT":"leaning forward",
    "GERI_EGIK_TEXT":"curved back",
    "SOLA_EGIK_TEXT":"tilted left",
    "SAGA_EGIK_TEXT":"tilted right",
    "SENSOR1":"sensor-1",
    "SENSOR2":"sensor-2",
    "SENSOR3":"sensor-3",
    "SENSOR4":"sensor-4",
    "SENSOR5":"sensor-5",
    "ORTAM_ISIK_SIDDETI_PYBOARD":"\%1\ \%2\  ambient light intensity socket \%3\ ",
    "YESIL_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ green light intensity socket \%3\ ",
    "MAVI_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ blue light intensity socket \%3\ ",
    "KIRMIZI_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ red light intensity socket \%3\ ",

    "ULTRASONIKINITPYBOARD" : "\%1\ \%2\ ultrasonic sensor initialize \%3\ ",
    "DISTANCEVALUEULTRASONIK" : "\%1\ \%2\ ultrasonic measured distance",
    "GETTIMEPYBOARD" : "\%1\ \%2\ \%2\ get  \%4\ time",

    
    "TEMPATURE_READ_PYBOT" : "\%1\ \%2\ temperature read \%3\ ",
    "RELATIVE_HUMIDITY_READ_PYBOT" : "\%1\ \%2\ read relative humidity \%3\ ",
    "WATERPROOF_TEMPATURE_INIT_PYBOARD" : "\%1\ \%2\ Initialize waterproof temperature sensor  \%3\ ",
    "WATERPROOF_TEMPATURE_READ_PYBOARD" : "\%1\ \%2\ Read temperature from waterproof sensor \%3\ ",
    "SOUNDINTENSITY" : "\%1\ \%2\ measure sound intensity \%3\ ",

    "BKY_CATEGORY_LIGHTS" : "Işık",
    "CLOCKWISE": "clockwise",
    "COUNTERCLOCKWISE": "counter clockwise",


    //FEELİNG

    "FEELING_BILGIN": 'Wise',
    "FEELING_HAVALI": 'Fast',
    "FEELING_MERAKLI": 'Curious',
    "FEELING_MUTLU": 'Happy',
    "FEELING_SEVGI": 'Love',
    "FEELING_UYKULU": 'Sleepy',
    "FEELING_KIZGIN":'Angry',
    "FEELING_KORKU": 'Fear',
    "FEELING_NESELİ": 'Cheerful',
    "FEELING_SASKIN": 'Shocked',
    "FEELING_UTANGAC": 'Shy',
    "FEELING_UZGUN": 'Sad',


     //DANCE

     "DANCE_TANGO": 'Tango',
     "DANCE_SAMBA": 'Samba',
     "DANCE_HIPHOP": 'Hip hop',
     "DANCE_SLOW": 'Slow',
     

    //MUSIC

     "HIPHOP": 'Hiphop',
     "AT": 'Horse',
     "AYAKSESI": 'Foot step',
     "BOZUKPARA": 'Coin',
     "HAV": 'Bark',
     "OYUNCAK": 'Toy',
     "PUAN": 'Point',
     "MIYAV": 'Meow',
     "SIREN": 'Siren',



     //DIRECTION

    "ILERI": 'forward',
    "GERI": 'backward',
    "SAG": 'right',
    "SOL": 'left',
    "Z": 'z',
    "HEPSI":"all",


    "CLOCKWISE": 'clockwise',
    "COUNTERCLOCKWISE": 'counterclockwise',

    
    //TILTED

    "LEFTTITLTED": 'tilted left',
    "RIGHTTITLTED": 'tilted right',
    "FRONTTITLTED": 'leant front',
    "BACKTITLTED": 'leant back',
    "STRAIGHTTITLTED":"straight",


    "TEMPATURE": 'tempature',
    "HUMIDITY": 'humidity',
    "SOILHUMIDITY": 'soil humidity',
    "SES": 'sound',
    "ISIK": 'light',
    "SALLANMA": 'shake',
    "ENGELVAR": 'obstacle',
    "ENGELYOK": 'no obstacle',


    "KIRMIZI": 'red',
    "YESIL": 'green',
    "MAVI": 'blue',
    "PEMBE": 'pink',
    "CAMGOBEGI": 'cyan',
    "SARI": 'yellow',
    "BEYAZ": 'white',
    "TURKUAZ": "turquoise",
    "SIYAH": "black",
    "MOR": "purple",

     //LIGHT SHOW

     "GOKKUSAGI": 'Rainbow',
     "KUZEYISIKLARI": 'Northern Lights',
     "KUYRUKLUYILDIZ": 'Comet',
     "METEORYAGMURU": 'Meteor Shower',
     "POLISARABASI": 'Police Car',
     "TRAFIKLAMBASI": 'Traffic Lights',
     "SAHNEISIKLARI": 'Stage Lights',
     "KAMPATESI": 'Camp Fire',

     // ZBOT_EĞLENCE

    "ZBOT_DUYGU": "\%1\ emotion",
    "ZBOT_DANCE": "\%1\  dance show",
    "ZBOT_SONG_SHOW": "\%1\ sound effect",
    "ZBOT_LIGHT_SHOW": "\%1\ light show",



    "ZBOT_SES_CAL": "play sound \%1\ ",
    "ZBOT_SES_CAL_BITENE_KADAR":  "play sound \%1\ until done",
    "ZBOT_SES_SES_SEVIYESI":  "set volume \%1\ ",
    "ZBOT_SES_SEVIYESI": "volume",
    "ZBOT_SES_SEVIYESINI_DEGISTIR": "change volume \%1\ ",
    "ZBOT_NOTA_CAL": "play note \%1\ for \%2\ beat",
    "ZBOT_TUM_SESLERI_DURDUR": "stop all sounds",
    "MY_PLAY_DRUM_FOR_BEATS": "play drum \%1\ for \%2\ beats",
    "MY_PLAY_MIDI_FOR_BEATS": "rest for \%1\ beats",
    "MY_REST_FOR_BEATS": "play note \%1\ for \%2\ beats",
    "MY_SET_INSTRUMENT": "set instrument to \%1\ ",
    "MY_SET_TEMPO": "set tempo to \%1\ ",
    "MY_CHANGE_TEMPO": "change tempo by \%1\ ",
    "MY_GET_TEMPO": "tempo",



    //ZBOT_OLAYLAR

    "ZBOT_WHEN_SHAKE_ROBOT": "when zBot shaken",
    "ZBOT_WHEN_TOUCH_ROBOT": "when zBot touched",
    "ZBOT_WHEN_ROBOT_RUN": "When zBot is starts up",
    "ZBOT_DETECT_SOUND_INTENSITY_VERTICAL": "when \%1\ şiddeti \%2\ değerinden büyükse",
    "ZBOT_DETECT_LIGHT_VERTICAL": "when \%1\ > \%2\ ",
    "ZBOT_WHEN_DETECT_TILTED_VERTICAL": "when zBot \%1\ ",
    "ZBOT_WHEN_DETECT_ROBOT_BUTTON": "\%1\ button pressed",
    "ZBOT_WHEN_DETECT_BARRIER_VERTICAL": "zBot has \%1\ at front",


    // ZBOT_EĞLENCE

    "ZBOT_DUYGU": "\%1\ emotion",
    "ZBOT_DANCE": "\%1\ dance show",
    "ZBOT_SONG_SHOW": "\%1\ sound effect",
    "ZBOT_LIGHT_SHOW": "\%1\ light show",


   //ZBOT_HAREKET
   "ZBOT_LINE_FOLLOWER": "follow the black line",
   "ZBOT_LEFT_RIGHT_CLOCK_SECOND": "turn \%1\ wheel \%2\ \%3\ second",
   "ZBOT_LEFT_RIGHT_CLOCK_POWER":  "turn \%1\ wheel \%2\ \%3\ power",
   "ZBOT_FORWARD_SECOND": "move forward \%1\ power \%2\ second",
   "ZBOT_BACKWARD_SECOND": "move backward \%1\ power \%2\ second",
   "ZBOT_TURNLEFT_UNTIL": "turn left \%1\ \%2\ degree",
   "ZBOT_TURNRIGHT_UNTIL": "turn right \%1\ \%2\ degree",
   "ZBOT_HAREKET_MENU_GIT": "move \%1\ \%2\ power",
   "ZBOT_HAREKET_SOL_SAG_GUC": "right wheel \%1\ power , left wheel \%2\ power",
   "ZBOT_HAREKET_DURDUR": "stop motion",


    //ZBOT_AYDINLATMA

    "ZBOT_ON_LED_YAK": "front lights \%1\ ",
    "ZBOT_ON_LED_KAPAT": "front lights off",
    "ZBOT_ARKA_LEDLERI_YAK": "rear lights \%1\ ",
    "ZBOT_ARKA_LED_KAPAT": "rear lights off",
    "ZBOT_ARKA_LED_SANIYELI": "rear lights \%1\ \%2\ second",
    "ZBOT_ARKA_LED_RGB_YAP": "rear lights R \%1\ G \%2\ B \%3\ ",
    "ZBOT_ARKA_LED_255": "rear lights \%1\ \%2\ bright",


    //ZBOT_LEDMATRIX

    "ZBOT_ZZ_LED_MATRIX": "\%1\ on screen",
    "ZBOT_ZZ_LED_MATRIX_SANIYE": "\%1\ on screen \%2\ second",
    "ZBOT_ZZ_LED_MATRIX_EKRANA_CIZ": "\%1\ on screen x:\%2\ y:\%3\ ",
    "ZBOT_LED_MATRIX_KAPAT": "screen off",
    "ZBOT_LED_MATRIX_YAZDIR":"show \%1\ on screen",
    "ZBOT_LED_MATRIX_KAYDIRARAK_YAZDIR": "scroll \%1\ on screen",
    "ZBOT_LED_YAK_X_Y": "light on x: \%1\ y:\%2\ ",
    "ZBOT_LED_KAPAT_X_Y": "light off x: \%1\ y:\%2\ ",
    "ZBOT_GOZ_KIRP_X_Y": "blink x: \%1\ y:\%2\ ",


    //ZBOT_SENSING

    "ZBOT_DUGME_BASILDIMI": "\%1\ button pressed",
    "CIHAZ_SALLANIYORMU":"shaken",
    "ALGILANAN_RENK":"detected color \%1\ ",
    "ZBOT_CIZGI_ALGILAMA_RENK": "line follower \%1\ side \%2\ detected",
    "ZBOT_ONUNDEKI_ENGELLER": "obstacle",
    "SES_SIDDETI": "sound level",
    "SALLANMA_SIDDETI":"shake level",
    "PIL_SEVIYESI":"battery level",
    "EGIM_ACISI":"pitch angle",
    "ORTAM_ISIK_SIDDETI":"light level",
    "YATIS_ACISI":"roll angle",


   






    "EVENTS_INFO0_WHENFLAGCLICKED": "Virtual Start Event Block",
    "EVENTS_INFO0_CLICKPLAY": "Click Play",
    "EVENTS_INFO0_SHAKEROBOT": "Shake Detection Block",
    "EVENTS_INFO0_TOUCHROBOT": "Touch Detection Block",
    "EVENTS_INFO0_DETECETSOUND": "Sound Intensity Detection Block",
    "EVENTS_INFO0_DETECT_SOUND_OFF": "No Sound Block",
    "EVENTS_INFO0_DETECT_LIGHT": "Light Intensity Detection Block",
    "EVENTS_INFO0_DETECT_LIGHT_OFF": "No Light Block",
    "EVENTS_INFO0_DETECT_COLOR": "Color Card Detection Block",
    "EVENTS_INFO0_DETECT_KEYPRESS": "Detect Keys",
    "EVENTS_INFO0_DETECT_BARRIER": "Obstacle Detection Block",
    "EVENTS_INFO0_WHENBROADCASTRECEIVE": "News Block",
    "EVENTS_INFO0_WHENBROADCAST": "News Sending Block",
    
    
    "MOVE_INFO0_HAREKET_ILERI": "Go Forward Block",
    "MOVE_INFO0_HAREKET_GERI": "Go Backward Block",
    "MOVE_INFO0_HAREKET_ILERI_SONSUZ": "Unlimited Forward Step Block",
    "MOVE_INFO0_HAREKET_GERI_SONSUZ": "Unlimited Backward Step Block",
    "MOVE_INFO0_HAREKET_SAGA_DON": "Right Turn Block",
    "MOVE_INFO0_HAREKET_SOLA_DON": "Left Turn Block",
    "MOVE_INFO0_HAREKET_DUR": "Stop Motion Block",
    "MOVE_INFO0_HAREKET_SPEED": "Speed Setting Block",
    "MOVE_INFO0_HAREKET_CIZGI_IZLEME": "Line Following Block",
    
    
    "CONTROL_INFO0_REPEAT": " Counted Repeat Block",
    "CONTROL_INFO0_CONTROL_FOREVER": "Repeat Continuously Block",
    "CONTROL_INFO0_CONTROLREPEAT": " Counted Repeat Block",
    "CONTROL_INFO0_CONTROLSTOPHORIZONTAL": "Stop Block",
    "CONTROL_INFO0_CONTROLWAIT": "Wait Block",
    
    
    
    
    
    "LIGHTS_INFO0_LIGHTON": "Turn On Front Lights Block",
    "LIGHTS_INFO0_LIGHTOFF":"Turn Off Front Lights Block",
    "LIGHTS_INFO0_LIGHTSOFF": "Turn Off Rear Lights Block",
    "LIGHTS_INFO0_LIGHTS": "Turn On Rear Lights Block",
    "LIGHTS_INFO0_LIGHTONOFF": "Turn On Front Lights Block",
    
    
    "LOOK_INFO0_LEDMATRIX": "Freehand Drawing on Screen Block",
    "LOOK_INFO0_LEDOFF": "Close Screen Block",
    "LOOK_INFO0_LEDTEXT": "Print on Screen Block",
    "LOOK_INFO0_HEART_EMOTION": "Draw Shape on Screen Block",
    
    
    
    "SOUND_INFO0_MELODILIST": "Select Note Level Block",
    "SOUND_INFO0_MELODI": " Play Note Block",
    "SOUND_INFO0_SOUND_OFF": "Mute Sound Block",
    "SOUND_INFO0_CUSTOMSOUND": "Play Recorded Sounds Block",
    "SOUND_INFO0_PLAYSOUND": "Adjust Sound Level Block",
    
    
    
    
    
    "SOUND_INFO1_MELODILIST": "Allows zBot to play at the selected note level.",
    "SOUND_INFO1_MELODI": "Allows zBot to play the selected note.",
    "SOUND_INFO1_SOUND_OFF": "Used to mute zBot's sound.",
    "SOUND_INFO1_CUSTOMSOUND": "Plays the selected sound.",
    "SOUND_INFO1_PLAYSOUND": "Used to adjust zBot's sound level.",
    
    "LOOK_INFO1_LEDMATRIX": "Used for freehand drawing on zBot's screen.",
    "LOOK_INFO1_LEDOFF": "Used to turn off zBot's screen.",
    "LOOK_INFO1_LEDTEXT": "Used to write text on zBot's screen.",
    "LOOK_INFO1_HEART_EMOTION": "Draws the selected shape on zBot's screen.",
    
    
    "LIGHTS_INFO1_LIGHTON": "Turns on the front lights of zBot in the selected color.",
    "LIGHTS_INFO1_LIGHTOFF": "Turns off the front lights of zBot.",
    "LIGHTS_INFO1_LIGHTSOFF": "Turns off the rear lights of zBot.",
    "LIGHTS_INFO1_LIGHTS": "Turns on the rear lights of zBot in the selected color.",
    "LIGHTS_INFO1_LIGHTONOFF": "Turns on the front lights of zBot in the selected color.",
    
    
    "CONTROL_INFO1_REPEAT": "Belli sayıda tekrar etmesi gereken kod blokları için kullanılır.",
    "CONTROL_INFO1_CONTROL_FOREVER": "Used for code blocks that need to be repeated continuously.",
    "CONTROL_INFO1_CONTROLREPEAT": "Used for code blocks that need to be repeated a certain number of times.",
    "CONTROL_INFO1_CONTROLSTOPHORIZONTAL": "Stops all codes.",
    "CONTROL_INFO1_CONTROLWAIT": "Used to wait for a specified number of seconds.",
    
    
    "MOVE_INFO1_HAREKET_ILERI": " zBot moves forward for the specified number of steps.",
    "MOVE_INFO1_HAREKET_GERI": "zBot moves backward for the specified number of steps.",
    "MOVE_INFO1_HAREKET_ILERI_SONSUZ": "zBot moves forward until stopped.",
    "MOVE_INFO1_HAREKET_GERI_SONSUZ": "zBot moves backward until stopped.",
    "MOVE_INFO1_HAREKET_SAGA_DON": "zBot turns 90 degrees to the right as specified.",
    "MOVE_INFO1_HAREKET_SOLA_DON": "zBot turns 90 degrees to the left as specified.",
    "MOVE_INFO1_HAREKET_DUR": "Stops the movement of zBot.",
    "MOVE_INFO1_HAREKET_SPEED": "Sets the motor rotation speed of zBot.",
    "MOVE_INFO1_HAREKET_CIZGI_IZLEME": "zBot follows the line.",
    
    
    "EVENTS_INFO1_WHENFLAGCLICKED": "When the yellow play button is clicked, the integrated blocks work.",
    "EVENTS_INFO1_CLICKPLAY": "zBot detects the keys on it and runs the relevant codes.",
    "EVENTS_INFO0_DETECT_KEYPRESS": "Detect Keys",
    "EVENTS_INFO1_SHAKEROBOT": " Runs the code when zBot is shaken.",
    "EVENTS_INFO1_TOUCHROBOT": "Runs the code when zBot is touched.",
    "EVENTS_INFO1_DETECETSOUND": "Runs the code when zBot detects sound at a specified level.",
    "EVENTS_INFO1_DETECT_SOUND_OFF": "Runs the code when zBot does not detect any sound.",
    "EVENTS_INFO1_DETECT_LIGHT": "Runs the code when zBot detects light at a specified level.",
    "EVENTS_INFO1_DETECT_LIGHT_OFF": "Runs the code when zBot does not detect any light.",
    "EVENTS_INFO1_DETECT_COLOR": "Runs the code when zBot detects the specified color card.",
    "EVENTS_INFO1_DETECT_KEYPRESS": "zBot detects the keys on it and runs the relevant codes.",
    "EVENTS_INFO1_DETECT_BARRIER": " Executes the code when zBot detects an obstacle or when the obstacle is removed.",
    "EVENTS_INFO1_WHENBROADCASTRECEIVE": " Runs the code when a specific event starts.",
    "EVENTS_INFO1_WHENBROADCAST": "Used to inform when a specific event starts.",



};






/*** EXPORTS FROM exports-loader ***/
module.exports = Blockly;
