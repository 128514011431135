import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {injectIntl, intlShape, defineMessages} from 'react-intl';
import VM from '../../mymodule/ztech-vm';

import zcodeDeviceLibraryContent from '../lib/libraries/devices.json';
import spriteTags from '../lib/libraries/sprite-tags';
import {setDeviceId, setDeviceName, setDeviceType} from '../reducers/device';

import LibraryDeviceComponent from '../components/library-device/library-device.jsx';
import RenderedTarget from '../../mymodule/ztech-vm/src/sprites/rendered-target.js';
import Sprite from '../../mymodule/ztech-vm/src/sprites/sprite.js';
const messages = defineMessages({
    libraryTitle: {
        defaultMessage: 'Choose a Sprite',
        description: 'Heading for the sprite library',
        id: 'gui.zcodeDeviceLibrary.chooseASprite'
    }
});

class ZcodeDevicesLibrary extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
    }
    handleItemSelect (item) {
        // Randomize position of library sprite
       console.log("item12");
       console.log(this.props.sprites);
       console.log("this.props.sprites");
        var aa = this.props.sprites;
        console.log(Object.values(aa).filter(x => x.isDevice==true));

        console.log(Object.values(aa).filter(x => x.isDevice==true).filter(k => k.name =="zbot").length != 0);

       if(Object.values(aa).filter(x => x.isDevice==true).filter(k => k.name ==item.name).length == 0){
        this.props.vm.addSprite(JSON.stringify(item),true).then(() => {
            this.props.onActivateBlocksTab();
            console.log("item.name: "+item.name);
            console.log("deviceData: "+this.props.deviceData);
            console.log(this.props.deviceData);
            const itemName = item.name.trim().replace(/i/g, 'ı').toLowerCase();
            const deviceName = this.props.deviceData[1].name.trim().replace(/i/g, 'i').toLowerCase();
            
            const isEqual = itemName === deviceName;
            console.log(itemName);
            console.log(deviceName);
            console.log(isEqual);
            const dev = this.props.deviceData.find(ext => ext.name.trim().replace(/i/g, 'i').toLowerCase() === deviceName);
            console.log("device seçilen");
            console.log(dev);
            this.props.onDeviceSelected(dev.deviceId, dev.name, dev.type);
        });
       }
       
       /*
       const dev = this.props.deviceData.find(ext => ext.deviceId === deviceName);
           
       this.props.onDeviceSelected(dev.deviceId, dev.name, dev.type);
       */
        
 /*
        const newClone = new RenderedTarget(new Sprite(null,this.props.vm.runtime,true), this.props.vm.runtime,true);
        this.props.vm.runtime.addTarget(newClone);
        console.log(newClone);

        this.props.vm.setEditingTarget(newClone.id);
*/

    }
    render () {
        return (
            <LibraryDeviceComponent
                data={zcodeDeviceLibraryContent}
                id="zcodeDevicesLibrary"
                tags={spriteTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

ZcodeDevicesLibrary.propTypes = {
    intl: intlShape.isRequired,
    onActivateBlocksTab: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    sprites: state.scratchGui.targets.sprites,
    deviceData: state.scratchGui.deviceData.deviceData,

});

const mapDispatchToProps = dispatch => ({

    onDeviceSelected: (id, name, type) => {
        dispatch(setDeviceId(id));
        dispatch(setDeviceName(name));
        dispatch(setDeviceType(type));
    },
   

});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ZcodeDevicesLibrary));
