import classNames from 'classnames';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {defineMessages, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import bowser from 'bowser';
import React from 'react';

import VM from '../../../mymodule/ztech-vm';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import CommunityButton from './community-button.jsx'; // eslint-disable-line no-unused-vars
import ShareButton from './share-button.jsx'; // eslint-disable-line no-unused-vars
import {ComingSoonTooltip} from '../coming-soon/coming-soon.jsx';
import Divider from '../divider/divider.jsx';
import LanguageSelector from '../../containers/language-selector.jsx';
import SaveStatus from './save-status.jsx'; // eslint-disable-line no-unused-vars
import ProjectWatcher from '../../containers/project-watcher.jsx'; // eslint-disable-line no-unused-vars
import MenuBarMenu from './menu-bar-menu.jsx';
import {MenuItem, MenuSection} from '../menu/menu.jsx';
import ProjectTitleInput from './project-title-input.jsx';
import AuthorInfo from './author-info.jsx';
import AccountNav from '../../containers/account-nav.jsx'; // eslint-disable-line no-unused-vars
import LoginDropdown from './login-dropdown.jsx'; // eslint-disable-line no-unused-vars
import SB3Downloader from '../../containers/sb3-downloader.jsx';
import SB3ServerDownloader from '../../containers/sb3-server-downloader.jsx';
import DeletionRestorer from '../../containers/deletion-restorer.jsx';
import TurboMode from '../../containers/turbo-mode.jsx';
import MenuBarHOC from '../../containers/menu-bar-hoc.jsx';
import {isScratchDesktop} from '../../lib/isScratchDesktop';
import {UPDATE_MODAL_STATE} from '../../lib/update-state.js';
import ProjectsModal from './projects-modal.jsx';
import { spriteTargetMode, deviceTargetMode } from "../../reducers/vertical-target-tab.js";

import {
    openTipsLibrary,
    openUploadProgress,
    openUpdateModal,
    openConnectionModal,
    openDeviceLibrary,
    openVerticalOrHorizontal,
    openComputerlessCoding,
    openDownloadZconnectModal,
    openFeedbackModal,
} from '../../reducers/modals';
import {setPlayer} from '../../reducers/mode';
import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy,
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload
} from '../../reducers/project-state';
import {
    openAboutMenu,
    closeAboutMenu,
    aboutMenuOpen,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    openToolboxMenu,
    closeToolboxMenu,
    fileMenuOpen,
    toolboxMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openSettingMenu,
    closeSettingMenu,
    settingMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen
} from '../../reducers/menus';
import {setStageSize} from '../../reducers/stage-size';
import {setUploadMode, setRealtimeMode} from '../../reducers/program-mode';
import {setjrMode,setNormalMode} from '../../reducers/jrchange';
import {truenewProject,falsenewProject} from '../../reducers/newproject.js';

import {
    activateTargetTab,
    KUKLALAR_TAB_INDEX,
    CIHAZ_TAB_INDEX
} from '../../reducers/target-tab';
import {setRealtimeConnection, clearConnectionModalPeripheralName} from '../../reducers/connection-modal';
import {setUpdate} from '../../reducers/update';
import {STAGE_SIZE_MODES} from '../../lib/layout-constants';

import collectMetadata from '../../lib/collect-metadata';

import styles from './menu-bar.css';

import helpIcon from '../../lib/assets/icon--tutorials.svg';
import mystuffIcon from './icon--mystuff.png'; // eslint-disable-line no-unused-vars
import profileIcon from './icon--profile.png'; // eslint-disable-line no-unused-vars
import remixIcon from './icon--remix.svg';
import dropdownCaret from './dropdown-caret.svg';
import languageIcon from '../language-selector/language-icon.svg';
import aboutIcon from './icon--about.svg';
import mylanguageIcon from './language.svg';
import myfolderIcon from './folder.svg';
import myhomeIcon from './home.svg';
import myplusIcon from './plus.svg';


import saveIcon from './basics/save2.png';
import linkSocketIcon from './icon--link-socket.svg'; // eslint-disable-line no-unused-vars
import replaceBlocksIcon2 from './replace.png'; // eslint-disable-line no-unused-vars
import replaceBlocksIcon from './replace_64px.png'; // eslint-disable-line no-unused-vars

import ConnectButton from "../connect-button/connect-button.jsx";

import scratchLogo from './logo.png';

import sharedMessages from '../../lib/shared-messages';

import Switch from 'react-switch';

import deviceIcon from './icon--device.svg';
import unconnectedIcon from './disconnect.svg';
import connectedIcon from './connect.png';
import screenshotIcon from './icon--screenshot.svg';
import settingIcon from './icon--setting.svg';

import uploadFirmwareIcon from './upload_50px.png';
import guideIcon from './guide.png';
import toolboxIcon from './toolbox.png';

import saveSvgAsPng from '../../../mymodule/ztech-save-svg-as-png';
import {showAlertWithTimeout} from '../../reducers/alerts';

import {setUserData} from '../../reducers/user-data';

import MyWorkspaceClass from "../../../mymodule/ztech-vm/src/util/myWorkspaceClass.js";


const ariaMessages = defineMessages({
    language: {
        id: 'gui.menuBar.LanguageSelector',
        defaultMessage: 'language selector',
        description: 'accessibility text for the language selection menu'
    },
    tutorials: {
        id: 'gui.menuBar.tutorialsLibrary',
        defaultMessage: 'Tutorials',
        description: 'accessibility text for the tutorials button'
    }
});

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};


MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({id, isRtl, children, className}) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

const AboutButton = props => (
    <Button
        className={classNames(styles.menuBarItem, styles.hoverable)}
        iconClassName={styles.aboutIcon}
        iconSrc={aboutIcon}
        onClick={props.onClick}
    />
);

AboutButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

class MenuBar extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            isProjectModalOpen: false,
        };
        bindAll(this, [
            'handleClickNew',
            'handleClickRemix',
            'handleClickSave',
            'handleClickSaveAsCopy',
            'handleClickSeeCommunity',
            'handleClickShare',
            'handleKeyPress',
            'handleLanguageMouseUp',
            'handleRestoreOption',
            'getSaveToComputerHandler',
            'restoreOptionMessage',
            'handleConnectionMouseUp',
            'handleKilavuz',
            'handleFeedback',
            'handleUploadFirmware',
            'handleuploadComputerlessCoding',
            'handleUploadDefaultCode',
            'handleSelectDeviceMouseUp',
            'handleSelectVerticalOrHorizontal',
            'handleDownloadZconnect',
            'handleProgramModeSwitchOnChange',
            'handleProgramModeUpdate',
            'handleScreenshot',
            'handleCheckUpdate',
            'handleClearCache',
            "openProjectFromServerModal",
            "openProjectFromComputer",
            "closeProjectFromServerModal"
        ]);
    }

    componentDidMount () {
        window.addEventListener('message', (event) => {
            /*
           var data={
                fullname: 'sinan altundag', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2N…3NjJ9.dzjUQwsbmhO-iY4GJEyBJPKrSCVHQcGX4vhH7gNuLNA', id: '64edf9c8ea31c70df2838351'
            }
*/

          const message = JSON.parse(event.data);
           // const message = JSON.parse(data);
        
           console.log(message);
            this.props.onSetUserData(message);

        
        });
        document.addEventListener('keydown', this.handleKeyPress);
        this.props.vm.on('PERIPHERAL_DISCONNECTED', this.props.onDisconnect);
        this.props.vm.on('PROGRAM_MODE_UPDATE', this.handleProgramModeUpdate);
        console.log("environ",process.env.REACT_APP_NODE_ENV);
    }
    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
        window.removeEventListener('message', () => {});
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED', this.props.onDisconnect);
        this.props.vm.removeListener('PROGRAM_MODE_UPDATE', this.handleProgramModeUpdate);
    }
    handleClickNew () {
       this.props.activateTargetTab(0);
        // if the project is dirty, and user owns the project, we will autosave.
        // but if they are not logged in and can't save, user should consider
        // downloading or logging in first.
        // Note that if user is logged in and editing someone else's project,
        // they'll lose their work.
        if(this.props.newProject === true){
            this.props.onFalsenewProject();
        }else{
            this.props.onTruenewProject();
        }

        const readyToReplaceProject = this.props.confirmReadyToReplaceProject(
            this.props.intl.formatMessage(sharedMessages.replaceProjectWarning)
        );
        this.props.onRequestCloseFile();
        if (readyToReplaceProject) {
            this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
        }

        this.props.onRequestCloseFile();

        if(this.props.isjr == true){
            setTimeout(() => {
                this.props.vm.runtime.setJrMonitorAdd();
    
            }, 400);
        }

       

    }
    handleClickRemix () {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }
    handleClickSave () {
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }
    handleClickSaveAsCopy () {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleClickSeeCommunity (waitForUpdate) {
        if (this.props.shouldSaveBeforeTransition()) {
            this.props.autoUpdateProject(); // save before transitioning to project page
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // immediately transition to project page
        }
    }
    handleClickShare (waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) { // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) { // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }
    handleRestoreOption (restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleKeyPress (event) {
        const modifier = bowser.mac ? event.metaKey : event.ctrlKey;
        if (modifier && event.key === 's') {
            this.props.onClickSave();
            event.preventDefault();
        }
    }
    getSaveToComputerHandler (downloadProjectCallback) {
        return () => {
            this.props.onRequestCloseFile();
            downloadProjectCallback();
            if (this.props.onProjectTelemetryEvent) {
                const metadata = collectMetadata(this.props.vm, this.props.projectTitle, this.props.locale);
                this.props.onProjectTelemetryEvent('projectDidSave', metadata);
            }
        };
    }
    handleLanguageMouseUp (e) {
        if (!this.props.languageMenuOpen) {
            this.props.onClickLanguage(e);
        }
    }
   
    restoreOptionMessage (deletedItem) {
        switch (deletedItem) {
        case 'Sprite':
            return (<FormattedMessage
                defaultMessage="Restore Sprite"
                description="Menu bar item for restoring the last deleted sprite."
                id="gui.menuBar.restoreSprite"
            />);
        case 'Sound':
            return (<FormattedMessage
                defaultMessage="Restore Sound"
                description="Menu bar item for restoring the last deleted sound."
                id="gui.menuBar.restoreSound"
            />);
        case 'Costume':
            return (<FormattedMessage
                defaultMessage="Restore Costume"
                description="Menu bar item for restoring the last deleted costume."
                id="gui.menuBar.restoreCostume"
            />);
        default: {
            return (<FormattedMessage
                defaultMessage="Restore"
                description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                id="gui.menuBar.restore"
            />);
        }
        }
    }
    handleConnectionMouseUp () {
        if (this.props.deviceId) {
            this.props.onOpenConnectionModal();
        } else {
            this.props.onDeviceIsEmpty();
        }
    }
    handleSelectDeviceMouseUp () {
        const blocks = document.querySelector('.blocklyWorkspace .blocklyBlockCanvas');
        if (blocks.getBBox().height === 0) {
            this.props.onOpenDeviceLibrary();
        } else {
            this.props.onWorkspaceIsNotEmpty();
        }
    }
    handleSelectVerticalOrHorizontal () {
        this.props.activateTargetTab(0);

        this.props.onOpenVerticalOrHorizontal();
    }
    handleDownloadZconnect(){
        console.log( this.props);
        this.props.onOpenDownloadZconnect();
    }
    handleProgramModeSwitchOnChange () {
        if (this.props.isRealtimeMode) {
            this.props.vm.runtime.setRealtimeMode(false);
        } else {
            /**
             * The realtime stage framwork didn't support STAGE_SIZE_MODES.hide,
             * so if the mode is hide switch to large mode.
             *  */
            if (this.props.stageSizeMode === STAGE_SIZE_MODES.hide) {
                this.props.onSetStageLarge();
            }
            this.props.vm.runtime.setRealtimeMode(true);
        }
    }
    handleJrOnChange () {
   
    
    }
    handleProgramModeUpdate (data) {
        if (data.isRealtimeMode) {
            this.props.onSetRealtimeMode();
        } else {
            this.props.onSetUploadMode();
        }
    }
    handleUploadFirmware () {
        
        if (this.props.deviceId) {
            this.props.vm.uploadFirmwareToPeripheral(this.props.deviceId);
            this.props.onSetRealtimeConnection(false);
            this.props.onOpenUploadProgress();
        } else {
            this.props.onNoPeripheralIsConnected();
        }
        
        if (this.props.stageSizeMode === STAGE_SIZE_MODES.hide) {
            this.props.onSetStageLarge();
        }
        this.props.vm.runtime.setRealtimeMode(true);

    }
    handleuploadComputerlessCoding () {
       console.log(this.props);
       // this.props.onOpenComputerlessModal();

       if (this.props.deviceId) {
        this.props.vm.uploadComputerlessCodingToPeripheral(this.props.deviceId,"uzay");
        this.props.onSetRealtimeConnection(false);
        this.props.onOpenUploadProgress();
        } else {
            this.props.onNoPeripheralIsConnected();
        }
    }
    handleUploadDefaultCode () {
       
        if (this.props.deviceId) {
            this.props.vm.uploadDefaultCodeToPeripheral(this.props.deviceId,this.props.codeEditorValue);
            this.props.onSetRealtimeConnection(false);
            this.props.onOpenUploadProgress();
        } else {
            this.props.onNoPeripheralIsConnected();
        }
    }

  

    async sendFeedback () {
        /*
        const client = new SMTPClient({
            user: "ztechfeedback@gmail.com",
            password: 'Ztech123!',
            host: 'smtp.gmail.com',
            ssl: true,
        });
        
        try {
            const message = await client.sendAsync({
                text: 'i hope this works',
                from: 'ekem57@hotmail.com',
                to: 'ztechfeedback@gmail.com',
                cc: 'else <else@your-email.com>',
                subject: 'testing emailjs',
            });
            console.log(message);
        } catch (err) {
            console.error(err);
        }

        /*

        const transporter = nodemailer.createTransport({
            host:  "smtp.gmail.com",
            port: 465,
            secure: true,
            auth: {
              // TODO: replace `user` and `pass` values from <https://forwardemail.net>
              user: "ztechfeedback@gmail.com",
              pass: "Ztech123!",
            },
          });

          const info = await transporter.sendMail({
            from: "ekem57@hotmail.com", // sender address
            to: "bar@example.com, baz@example.com", // list of receivers
            subject: "Hello ✔", // Subject line
            text: "Hello world?", // plain text body
            html: "<b>Hello world?</b>", // html body
        });
        */
    }
    
    
    handleKilavuz () {
     
       // var ws = MyWorkspaceClass.getWorkspace();
      /*
       this.handleScreenshot();
       */
        if(this.props.locale=="tr"){
            window.open(
                'https://drive.google.com/file/d/1YRr077eQTK5vVi58ghYqP8SDmL2zskh4/view?usp=sharing',
                '_blank' 
                );
        }else{
            window.open(
                'https://drive.google.com/file/d/1ApuK4DQehEuZXd5XQ64IdVVHJVMyQ8Fb/view?usp=sharing',
                '_blank' 
                );
        }
        
    
    }


    handleFeedback () {
        this.props.onOpenFeedback();
        
     }
  

    handleScreenshot () {
        const blocks = document.querySelector('.blocklyWorkspace .blocklyBlockCanvas');
        if (blocks.getBBox().height === 0) {
            this.props.onWorkspaceIsEmpty();
        } else {
            const transform = blocks.getAttribute('transform');
            const scale = parseFloat(transform.substring(transform.indexOf('scale') + 6, transform.length - 1));
            const data = new Date();

            saveSvgAsPng.saveSvgAsPng(blocks, `${this.props.projectTitle}-${data.getTime()}.png`, {
                left: blocks.getBBox().x * scale,
                top: blocks.getBBox().y * scale,
                height: blocks.getBBox().height * scale,
                width: blocks.getBBox().width * scale,
                scale: 2 / scale,
                encoderOptions: 1
            });
        }
    }
    handleCheckUpdate () {
        this.props.onSetUpdate({phase: UPDATE_MODAL_STATE.checkingApplication});
        this.props.onClickCheckUpdate();
    }
    handleClearCache () {
        const readyClearCache = this.props.confirmClearCache(
            this.props.intl.formatMessage(sharedMessages.clearCacheWarning)
        );
        if (readyClearCache) {
            this.props.onClickClearCache();
        }
    }
    buildAboutMenu (onClickAbout) {
        if (!onClickAbout) {
            // hide the button
            return null;
        }
        if (typeof onClickAbout === 'function') {
            // make a button which calls a function
            return <AboutButton onClick={onClickAbout} />;
        }
        // assume it's an array of objects
        // each item must have a 'title' FormattedMessage and a 'handleClick' function
        // generate a menu with items for each object in the array
        return (
            onClickAbout.map(itemProps => (
                <MenuItem
                    key={itemProps.title}
                    isRtl={this.props.isRtl}
                    onClick={this.wrapAboutMenuCallback(itemProps.onClick)}
                >
                    {itemProps.title}
                </MenuItem>
            ))
        );
    }
    wrapAboutMenuCallback (callback) {
        return () => {
            callback();
            this.props.onRequestCloseAbout();
        };
    }
    openProjectFromServerModal () {
        console.log("openProjectFromServerModal")
        this.setState({isProjectModalOpen: true});
       // this.props.onOpenProjectModal();

        this.props.onRequestCloseFile();
    }
    openProjectFromComputer () {
        this.props.activateTargetTab(0);

        this.props.onStartSelectingFileUpload();
        this.props.onRequestCloseFile();
    }
    closeProjectFromServerModal () {
        this.setState({isProjectModalOpen: false});
    }
    render () {
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage="Save as a copy"
                description="Menu bar item for saving as a copy"
                id="gui.menuBar.saveAsCopy"
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage="Remix"
                description="Menu bar item for remixing"
                id="gui.menuBar.remix"
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.new"
            />
        );
        const checkUpdate = (
            <FormattedMessage
                defaultMessage="Check update"
                description="Menu bar item for check update"
                id="gui.menuBar.checkUpdate"
            />
        );
        const installDriver = (
            <FormattedMessage
                defaultMessage="Install driver"
                description="Menu bar item for install drivers"
                id="gui.menuBar.installDriver"
            />
        );
        const clearCache = (
            <FormattedMessage
                defaultMessage="Clear cache and restart"
                description="Menu bar item for clear cache and restart"
                id="gui.menuBar.clearCacheAndRestart"
            />
        );
        const firmwareUpload = (
            <FormattedMessage
                defaultMessage="Firmware Upload"
                description="Menu bar item for clear cache and restart"
                id="gui.menuBar.firmwareUpload"
            />
        );
        const kidsUpload = (
            <FormattedMessage
                defaultMessage="Bilgisayarsız Kodlama"
                description="Button to upload the realtime firmware"
                id="gui.menuBar.uploadBilgisayarsızKodlama"
                    />
        );
        const DeviceReset = (
            <FormattedMessage
                defaultMessage="Device Reset"
                description="Menu bar item for clear cache and restart"
                id="gui.menuBar.DeviceReset"
            />
        );
        const CalibarateDevice = (
            <FormattedMessage
                defaultMessage="Calibarate Device"
                description="Menu bar item for clear cache and restart"
                id="gui.menuBar.CalibarateDevice"
            />
        );
        const DownloadZconnect = (
            <FormattedMessage
                defaultMessage="Download Zconnect"
                description="Menu bar item for clear cache and restart"
                id="gui.menuBar.DownloadZconnect"
            />
        );
        // eslint-disable-next-line no-unused-vars
        const remixButton = (
            <Button
                className={classNames(
                    styles.menuBarButton,
                    styles.remixButton
                )}
                iconClassName={styles.remixButtonIcon}
                iconSrc={remixIcon}
                onClick={this.handleClickRemix}
            >
                {remixMessage}
            </Button>
        );
        // Show the About button only if we have a handler for it (like in the desktop app)
        const aboutButton = this.buildAboutMenu(this.props.onClickAbout);
        console.log("deneme", process.env.REACT_APP_NODE_ENV);
        return (
            <Box
                className={classNames(
                    this.props.className,
                    styles.menuBar
                )}
            >
                <div className={styles.mainMenu}>
                    <div className={classNames(styles.menuBarItem)}>
                        <h1 className={classNames(styles.fontFacegm)} >ztech</h1>
                    <h1 className={classNames(styles.fontDot)}>a</h1>
                    </div>

                    {this.props.activeTabIndex == 0 && this.props.deviceId != null  ? <ConnectButton
                        onShowMessageBox={this.props.onShowMessageBox}
                    /> :
                    null }

                    
                    <div
                        className={classNames(styles.menuBarItem, styles.hoverable)}
                        onMouseUp={this.handleSelectVerticalOrHorizontal} >
                        <img
                                className={styles.menuBasicIcons}
                                src={myhomeIcon}
                        />
                    </div>

                  

                            
                    {(this.props.canChangeLanguage) && (<div
                        className={classNames(styles.menuBarItem, styles.hoverable, styles.languageMenu)}
                    >
                        <div>
                            <img
                                className={styles.menuBasicIcons}
                                src={mylanguageIcon}
                            />
                          
                        </div>
                        <LanguageSelector  label={this.props.intl.formatMessage(ariaMessages.language)} />
                    </div>)}
                    {(this.props.canManageFiles) && (
                        <div
                            className={classNames(styles.menuBarItem, styles.hoverable, {
                                [styles.active]: this.props.fileMenuOpen
                            })}
                            onMouseUp={this.props.onClickFile}
                        >
                             <img
                                className={styles.menuBasicIcons}
                                src={myfolderIcon}
                            />
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu)}
                                open={this.props.fileMenuOpen}
                                place={this.props.isRtl ? 'left' : 'right'}
                                onRequestClose={this.props.onRequestCloseFile}
                            >
                                <MenuSection>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.handleClickNew}
                                    >
                                        {newProjectMessage}
                                    </MenuItem>
                                </MenuSection>
                                {(this.props.canSave || this.props.canCreateCopy || this.props.canRemix) && (
                                    <MenuSection>
                                        {this.props.canSave && (
                                            <MenuItem onClick={this.handleClickSave}>
                                                {saveNowMessage}
                                            </MenuItem>
                                        )}
                                        {this.props.canCreateCopy && (
                                            <MenuItem onClick={this.handleClickSaveAsCopy}>
                                                {createCopyMessage}
                                            </MenuItem>
                                        )}
                                        {this.props.canRemix && (
                                            <MenuItem onClick={this.handleClickRemix}>
                                                {remixMessage}
                                            </MenuItem>
                                        )}
                                    </MenuSection>
                                )}
                                <MenuSection>
                                    <MenuItem
                                        onClick={this.openProjectFromComputer}
                                    >
                                        {this.props.intl.formatMessage(sharedMessages.loadFromComputerTitle)}
                                    </MenuItem>
                                    <SB3Downloader>{(className, downloadProjectCallback) => (
                                        <MenuItem
                                            className={className}
                                            onClick={this.getSaveToComputerHandler(downloadProjectCallback)}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Save to your computer"
                                                description="Menu bar item for downloading a project to your computer" // eslint-disable-line max-len
                                                id="gui.menuBar.downloadToComputer"
                                            />
                                        </MenuItem>
                                    )}</SB3Downloader>
                                    <MenuItem
                                        onClick={this.openProjectFromServerModal}
                                    >
                                        {this.props.intl.formatMessage(sharedMessages.openProjectsFromServer)}
                                    </MenuItem>
                                </MenuSection>
                            </MenuBarMenu>
                        </div>
                    )}

                  
                    
                    {this.props.deviceId != null ? 
                    null :
                    <div
                        className={classNames(styles.menuBarItem,
                            this.props.isRealtimeMode ? styles.hoverable : styles.disabled,
                            {[styles.active]: this.props.editMenuOpen
                            })}
                        onMouseUp={this.props.isRealtimeMode ? this.props.onClickEdit : null}
                    >
                        <div className={classNames(styles.menuBarText)} >
                            <FormattedMessage
                                defaultMessage="Edit"
                                description="Text for edit dropdown menu"
                                id="gui.menuBar.edit"
                            />
                        </div>
                        <MenuBarMenu
                            className={classNames(styles.menuBarMenu)}
                            open={this.props.editMenuOpen}
                            place={this.props.isRtl ? 'left' : 'right'}
                            onRequestClose={this.props.onRequestCloseEdit}
                        >
                            <DeletionRestorer>{(handleRestore, {restorable, deletedItem}) => (
                                <MenuItem
                                    className={classNames({[styles.disabled]: !restorable})}
                                    onClick={this.handleRestoreOption(handleRestore)}
                                >
                                    {this.restoreOptionMessage(deletedItem)}
                                </MenuItem>
                            )}</DeletionRestorer>
                            <MenuSection>
                                <TurboMode>{(toggleTurboMode, {turboMode}) => (
                                    <MenuItem onClick={toggleTurboMode}>
                                        {turboMode ? (
                                            <FormattedMessage
                                                defaultMessage="Turn off Turbo Mode"
                                                description="Menu bar item for turning off turbo mode"
                                                id="gui.menuBar.turboModeOff"
                                            />
                                        ) : (
                                            <FormattedMessage
                                                defaultMessage="Turn on Turbo Mode"
                                                description="Menu bar item for turning on turbo mode"
                                                id="gui.menuBar.turboModeOn"
                                            />
                                        )}
                                    </MenuItem>
                                )}</TurboMode>
                            </MenuSection>
                        </MenuBarMenu>
                    </div>
                    }

                 
                                    
                    
                <Divider className={classNames(styles.divider)} />
                <p className={classNames(styles.fullnameText)}>
                    {this.props.userData.fullname}
                </p>
                <div className={styles.fileMenu}>
                    {this.props.canEditTitle ? (
                        <div className={classNames(styles.menuBarItem, styles.growable)}>
                            <MenuBarItemTooltip
                                enable
                                id="title-field"
                            >
                                <ProjectTitleInput
                                    className={classNames(styles.titleFieldGrowable)}
                                />
                            </MenuBarItemTooltip>
                        </div>
                    ) : ((this.props.authorUsername && this.props.authorUsername !== this.props.username) ? (
                        <AuthorInfo
                            className={styles.authorInfo}
                            imageUrl={this.props.authorThumbnailUrl}
                            projectTitle={this.props.projectTitle}
                            userId={this.props.authorId}
                            username={this.props.authorUsername}
                        />
                    ) : null)}
                    {(this.props.canManageFiles) && (
                        <SB3ServerDownloader>{(className, downloadProjectCallback) => (
                            <div
                                className={classNames(styles.menuBarItem, styles.hoverable)}
                                onClick={this.getSaveToComputerHandler(downloadProjectCallback)}
                            >
                                <img
                                    className={styles.menuBasicIcons}
                                    src={saveIcon}
                                />
                            </div>
                        )}</SB3ServerDownloader>
                    )}
 
                    <Divider className={classNames(styles.divider)} />
                    
                <div
                    className={ classNames(styles.menuBarItem, styles.menuBarText)}
                  //  onMouseUp={this.handleKilavuz}
                >
                 { this.props.peripheralName != null ? 
                        <img
                            alt="Connected"
                            className={classNames(styles.uploadFirmwareLogo)}
                            draggable={false}
                            src={connectedIcon}
                        /> : 
                        <img
                            alt="NotConnected"
                            className={classNames(styles.uploadFirmwareLogo)}
                            draggable={false}
                            src={unconnectedIcon}
                        /> 

                 }

                       { this.props.peripheralName != null ? 
                        <FormattedMessage
                            defaultMessage="Cihaz Bağlı"
                            description="Button to upload the realtime firmware"
                            id="gui.menuBar.deviceConnected"
                        />
                        :
                        <FormattedMessage
                            defaultMessage="Cihaz Bağlı Değil"
                            description="Button to upload the realtime firmware"
                            id="gui.menuBar.deviceNotConnected"
                        />
                       }
                </div> 
                    

                </div>
                 
                </div>

                <div className={styles.tailMenu}>
                  
                    <Divider className={ this.props.isRealtimeMode ? styles.firmwareVisible : classNames(styles.divider)} />

                <div
                    className={ this.props.isRealtimeMode ? styles.firmwareVisible : classNames(styles.menuBarItem, styles.menuBarText, this.props.isRealtimeMode &&
                    this.props.peripheralName ? styles.hoverable : styles.hoverable)}
                    onMouseUp={this.props.peripheralName  ?
                    this.handleuploadComputerlessCoding : null}
                    >
                    <img
                        alt="UploadFirmware"
                        className={classNames(styles.uploadFirmwareLogo)}
                        draggable={false}
                        src={uploadFirmwareIcon}
                    />
                    <FormattedMessage
                        defaultMessage="Bilgisayarsız Kodlama"
                        description="Button to upload the realtime firmware"
                        id="gui.menuBar.uploadBilgisayarsızKodlama"
                    />
                </div> 
                <Divider className={ this.props.isRealtimeMode ? styles.firmwareVisible : classNames(styles.divider)} />

                <div
                    className={ classNames(styles.menuBarItem, styles.menuBarText, this.props.isRealtimeMode &&
                    this.props.peripheralName ? styles.hoverable : styles.hoverable)}
                    onMouseUp={this.handleKilavuz}
                    >
                    
                    <img
                        alt="kılavuz"
                        className={classNames(styles.uploadFirmwareLogo)}
                        draggable={false}
                        src={guideIcon}
                    />
                    <FormattedMessage
                        defaultMessage="Kılavuz"
                        description="Button to upload the realtime firmware"
                        id="gui.menuBar.guide"
                    />
                </div> 

                <div
                    className={ classNames(styles.menuBarItem, styles.menuBarText, this.props.isRealtimeMode &&
                    this.props.peripheralName ? styles.hoverable : styles.hoverable)}
                    onMouseUp={this.handleFeedback}
                    >
                    
                    <FormattedMessage
                        defaultMessage="Feed Back"
                        description="Button to upload the realtime firmware"
                        id="gui.menuBar.feedback"
                    />
                </div> 


                    <div
                            className={classNames(styles.toolboxItem, styles.hoverable, {
                                [styles.active]: this.props.toolboxMenuOpen
                            })}
                            onMouseUp={this.props.onClickToolbox}
                            onMouseOver={this.props.onClickToolbox}
                            onMouseLeave={this.props.onRequestCloseToolbox}

                        >
                            <div
                    className={ classNames(styles.toolboxItem, styles.menuBarText)}
                  
                    >
                    <img
                        alt="Araç Kutusu"
                        className={classNames(styles.uploadFirmwareLogo)}
                        draggable={false}
                        src={toolboxIcon}
                    />
                    <FormattedMessage
                        defaultMessage="Araç Kutusu"
                        description="Button to upload the realtime firmware"
                        id="gui.menuBar.toolbox"
                    />


                    
                </div> 
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu,styles.size)}
                                open={this.props.toolboxMenuOpen}
                                place={this.props.isRtl ? 'left' : 'right'}
                                onRequestClose={this.props.onRequestCloseToolbox}
                            >
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.props.peripheralName ? this.handleUploadFirmware : null}
                                    >
                                        {firmwareUpload}
                                    </MenuItem>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.props.peripheralName ? this.handleuploadComputerlessCoding : null}
                                    >
                                        {kidsUpload}
                                    </MenuItem>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.props.peripheralName ? this.handleUploadDefaultCode : null}
                                    >
                                        {DeviceReset}
                                    </MenuItem>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                       // onClick={this.handleClickNew}
                                    >
                                        {CalibarateDevice}
                                    </MenuItem>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.handleDownloadZconnect}
                                    >
                                        {DownloadZconnect}
                                    </MenuItem>
                             
                            </MenuBarMenu>
                        </div>


                    <Divider className={ this.props.isRealtimeMode ? styles.firmwareVisible : classNames(styles.divider)} />



                {
                    this.props.isjr ?
                    <div
                        className={classNames(styles.menuBarItem, styles.hoverable)}
                        onMouseUp={this.handleSelectDeviceMouseUp}
                    >
                        <img
                            className={styles.deviceIcon}
                            src={myplusIcon}
                        />
                        {/*
                            this.props.deviceName ? (
                                <div>
                                    {this.props.deviceName}
                                </div>
                            ) : (
                                <FormattedMessage
                                    defaultMessage="No device selected"
                                    description="Text for menubar no device select button"
                                    id="gui.menuBar.noDeviceSelected"
                                />
                            ) */}
                    </div>  : <div></div>
              
                }
               
            

                   

                             
                  

                    


                    
                    {isScratchDesktop() ? (
                        <div
                            className={classNames(styles.menuBarItem, styles.hoverable, {
                                [styles.active]: this.props.settingMenuOpen
                            })}
                            onMouseUp={this.props.onClickSetting}
                        >
                            <img
                                className={styles.settingIcon}
                                src={settingIcon}
                            />
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu)}
                                open={this.props.settingMenuOpen}
                                place={this.props.isRtl ? 'right' : 'left'}
                                onRequestClose={this.props.onRequestCloseSetting}
                            >
                                <MenuSection>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.handleCheckUpdate}
                                    >
                                        {checkUpdate}
                                    </MenuItem>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.handleClearCache}
                                    >
                                        {clearCache}
                                    </MenuItem>
                                </MenuSection>
                                <MenuSection>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.props.onClickInstallDriver}
                                    >
                                        {installDriver}
                                    </MenuItem>
                                </MenuSection>
                                <MenuSection>
                                    {typeof this.props.onClickAbout === 'object' ? aboutButton : null}
                                </MenuSection>
                            </MenuBarMenu>
                        </div>
                    ) : null}
                </div>
                <ProjectsModal
                    id="projectModal"
                    isOpen={this.state.isProjectModalOpen}
                    onRequestClose={this.closeProjectFromServerModal}
                    userData={this.props.userData}
                    intl={this.props.intl}
                    isShowingWithoutId={this.props.isShowingProject}
                    loadingState={this.state.loadingState}
                    projectChanged={this.state.projectChanged}
                    userOwnsProject={this.props.userOwnsProject}
                    onLoadingStarted={this.props.onLoadingStarted}
                    vm={this.props.vm}
                    cancelFileUpload={this.props.cancelFileUpload}
                    onShowMessageBox={this.props.onShowMessageBox}
                    onLoadingClose={this.props.onLoadingClose}
                   
                />

                { (typeof this.props.onClickAbout === 'function') ? aboutButton : null}
            </Box>
        );
    }
}

MenuBar.propTypes = {
    onOpenComputerlessModal: PropTypes.func,
    aboutMenuOpen: PropTypes.bool,
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    confirmClearCache: PropTypes.func,
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    toolboxMenuOpen: PropTypes.bool,
    settingMenuOpen: PropTypes.bool,
    intl: intlShape,
    isUpdating: PropTypes.bool,
    isRealtimeMode: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isSupportSwitchMode: PropTypes.bool,
    isToolboxUpdating: PropTypes.bool.isRequired,
    languageMenuOpen: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    loginMenuOpen: PropTypes.bool,
    logo: PropTypes.string,
    onClickAbout: PropTypes.oneOfType([
        PropTypes.func, // button mode: call this callback when the About button is clicked
        PropTypes.arrayOf( // menu mode: list of items in the About menu
            PropTypes.shape({
                title: PropTypes.string, // text for the menu item
                onClick: PropTypes.func // call this callback when the menu item is clicked
            })
        )
    ]),
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickToolbox: PropTypes.func,
    onClickSetting: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onClickCheckUpdate: PropTypes.func,
    onClickClearCache: PropTypes.func,
    onClickInstallDriver: PropTypes.func,
    onLogOut: PropTypes.func,
    onNoPeripheralIsConnected: PropTypes.func.isRequired,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onProjectTelemetryEvent: PropTypes.func,
    onRequestOpenAbout: PropTypes.func,
    onRequestCloseAbout: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseSetting: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    projectTitle: PropTypes.string,
    realtimeConnection: PropTypes.bool.isRequired,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    showComingSoon: PropTypes.bool,
    userOwnsProject: PropTypes.bool,
    username: PropTypes.string,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    vm: PropTypes.instanceOf(VM).isRequired,
    onSetUploadMode: PropTypes.func,
    onSetRealtimeConnection: PropTypes.func.isRequired,
    onSetRealtimeMode: PropTypes.func,
    onSetUpdate: PropTypes.func.isRequired,
    onOpenConnectionModal: PropTypes.func,
    onOpenUploadProgress: PropTypes.func,
    peripheralName: PropTypes.string,
    onDisconnect: PropTypes.func.isRequired,
    onWorkspaceIsEmpty: PropTypes.func.isRequired,
    onWorkspaceIsNotEmpty: PropTypes.func.isRequired,
    onOpenDeviceLibrary: PropTypes.func,
    onOpenVerticalOrHorizontal: PropTypes.func,
    onOpenDownloadZconnect: PropTypes.func,

    onSetStageLarge: PropTypes.func.isRequired,
    deviceId: PropTypes.string,
    deviceName: PropTypes.string,
    onDeviceIsEmpty: PropTypes.func,
    isjr: PropTypes.bool,
    onSetJrchange: PropTypes.func,
    activeTabIndex: PropTypes.number,
    onSetUserData: PropTypes.func,
    userData: PropTypes.object,
};

MenuBar.defaultProps = {
    logo: scratchLogo,
    onShare: () => {}
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        aboutMenuOpen: aboutMenuOpen(state),
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        toolboxMenuOpen: toolboxMenuOpen(state),
        settingMenuOpen: settingMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isUpdating: getIsUpdating(loadingState),
        isRealtimeMode: state.scratchGui.programMode.isRealtimeMode,
        isRtl: state.locales.isRtl,
        isShowingProject: getIsShowingProject(loadingState),
        isSupportSwitchMode: state.scratchGui.programMode.isSupportSwitchMode,
        isToolboxUpdating: state.scratchGui.toolbox.isToolboxUpdating,
        languageMenuOpen: languageMenuOpen(state),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        projectTitle: state.scratchGui.projectTitle,
        realtimeConnection: state.scratchGui.connectionModal.realtimeConnection,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        username: user ? user.username : null,
        userOwnsProject: ownProps.authorUsername && user &&
            (ownProps.authorUsername === user.username),
        stageSizeMode: state.scratchGui.stageSize.stageSize,
        vm: state.scratchGui.vm,
        peripheralName: state.scratchGui.connectionModal.peripheralName,
        deviceId: state.scratchGui.device.deviceId,
        deviceName: state.scratchGui.device.deviceName,
        isjr: state.scratchGui.isjr,
        newProject: state.scratchGui.newProject,

        codeEditorValue: state.scratchGui.code.codeEditorValue,
        activeTabIndex: state.scratchGui.editorTab.activeTabIndex,
        userData: state.scratchGui.userData,
        isShowingWithoutId: getIsShowingWithoutId(loadingState),
        loadingState:state.scratchGui.projectState.loadingState,
        projectChanged: state.scratchGui.projectChanged,
        myProjectModal: state.scratchGui.myProjectModal,
        currentLocale: state.locales.locale,
        activeTargetTabIndex: state.scratchGui.targetTab.activeTargetTabIndex,
    };
};

const mapDispatchToProps = dispatch => ({
    activateTargetTab: tab => dispatch(activateTargetTab(tab)),
    onOpenComputerlessModal: () => dispatch(openComputerlessCoding()),
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickToolbox: () => dispatch(openToolboxMenu()),
    onRequestCloseToolbox: () => dispatch(closeToolboxMenu()),
    onClickSetting: () => dispatch(openSettingMenu()),
    onRequestCloseSetting: () => dispatch(closeSettingMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onRequestOpenAbout: () => dispatch(openAboutMenu()),
    onRequestCloseAbout: () => dispatch(closeAboutMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    onSetUploadMode: () => {
        dispatch(setUploadMode());
        dispatch(setRealtimeConnection(false));
    },
    onSetRealtimeConnection: state => dispatch(setRealtimeConnection(state)),
    onSetRealtimeMode: () => dispatch(setRealtimeMode()),
    onSetStageLarge: () => dispatch(setStageSize(STAGE_SIZE_MODES.large)),
    onSetJrchange: () => dispatch(setjrMode()),
    onSetNormalchange: () => dispatch(setNormalMode()),
    onTruenewProject: () => dispatch(truenewProject()),
    onFalsenewProject: () => dispatch(falsenewProject()),

    onOpenConnectionModal: () => dispatch(openConnectionModal()),
    onOpenUploadProgress: () => dispatch(openUploadProgress()),
    onDisconnect: () => {
        dispatch(clearConnectionModalPeripheralName());
        dispatch(setRealtimeConnection(false));
    },
    onSetUpdate: message => {
        dispatch(setUpdate(message));
        dispatch(openUpdateModal());
    },
    onNoPeripheralIsConnected: () => showAlertWithTimeout(dispatch, 'connectAPeripheralFirst'),
    onWorkspaceIsEmpty: () => showAlertWithTimeout(dispatch, 'workspaceIsEmpty'),
    onWorkspaceIsNotEmpty: () => showAlertWithTimeout(dispatch, 'workspaceIsNotEmpty'),
    onOpenDeviceLibrary: () => dispatch(openDeviceLibrary()),
    onOpenVerticalOrHorizontal: () => dispatch(openVerticalOrHorizontal()),
    onOpenDownloadZconnect: () => dispatch(openDownloadZconnectModal()),
    onOpenFeedback: () => dispatch(openFeedbackModal()),

    onDeviceIsEmpty: () => showAlertWithTimeout(dispatch, 'selectADeviceFirst'),
    onSetUserData: data => dispatch(setUserData(data)),
    onLoadingStarted: () => dispatch(openLoadingProject()),
    cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
    onChangeLanguage: locale => {
        dispatch(selectLocale(locale));
    }
});

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MenuBar);
